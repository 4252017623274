import React, { Fragment } from 'react'
// import Counter from '../../components/counter/Counter'
import {
  Heading,
  Text,
  Button, Tooltip, Box,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList
} from "@chakra-ui/react";
import axios from 'axios';
import SkyHarborSales from './SkyHarborSales';
import SkyHarborSaleHistory from './SkyHarborSaleHistory';

export const MarketplacePage = ({changeTheme, darkMode}) => {

  // If there are no ergosaurs for sale, disables button + tooltip
  const forSale = false;

  const checkArtist = () => {
    window.open("https://twitter.com/ergosaurs/status/1440745330039930883");
  }
  const gotoAuction = () => {
    window.open("https://ergoauctions.org/#/auction/active?type=picture&artist=9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi");
  }
  

  return (
    <Fragment >

      {/* Banner */}
      {/* <HomeHero /> */}
      <h1 style={{display:'none'}}>Ergosaurs</h1>

      {/* Ergosaurs Introduction */}
      {/* <HomeTop /> */}

      {/* Ongoing Auctions */}
      <Heading letterSpacing={"wide"} textAlign={"center"} mb="4" mt="6" id={'auctions'} >Ergosaurs on SkyHarbor</Heading>
      {/* <Box m="4" textAlign={"center"} height={"40px"}>
        <CreateAuctionButton />
      </Box> */}
      <Tabs variant='soft-rounded' size={"lg"} align="center">
        <TabList my="8">
          <Tab fontSize={"xl"}>For Sale</Tab>
          <Tab fontSize={"xl"}>Previously Sold</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SkyHarborSales/>
          </TabPanel>
          <TabPanel>
            <SkyHarborSaleHistory/>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* <AuctionsWrapper /> */}


    </Fragment>
  )
}
