import React, { Fragment } from 'react'
import { useNavigate } from "react-router-dom";
// import Counter from '../../components/counter/Counter'
import {
  Heading,
  Text,
  Stack,
  Flex,
  Button, Tooltip, Box, Image, Divider
} from "@chakra-ui/react";
import { FaGavel  } from 'react-icons/fa';
import { MoonIcon, SunIcon } from '@chakra-ui/icons'

import Features from '../../components/Features/Features';
import { HomeHero } from '../../components/HomeHero/HomeHero'
import { HomeTop } from '../../components/HomeTop/HomeTop'
import DinoEggs from '../../components/DinoEggs/DinoEggs'
// import Features from '../../components/Features/Features'
import CardList from '../../components/CardList/CardList'
import AuctionsList from '../../components/CardList/AuctionsList'
import RaffleFeature from '../../components/RaffleFeature/RaffleFeature'

import ColorList from '../../components/ColorList/ColorList'
import egg1 from '../../assets/images/0023.png'
import egg2 from '../../assets/images/0493.png'
import axios from 'axios';
import { MdSailing } from 'react-icons/md';
import Events from '../../components/Events/Events';

export const LandingPage = ({changeTheme, darkMode}) => {

  const navigate = useNavigate();

  // If there are no ergosaurs for sale, disables button + tooltip
  const forSale = false;

  const checkArtist = () => {
    window.open("https://twitter.com/ergosaurs/status/1440745330039930883");
  }
  const gotoAuction = () => {
    window.open("https://ergoauctions.org/#/auction/active?type=picture&artist=9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi");
  }
  const gotoAuctionsPage = () => {
    navigate("/auctions")
  }
  const gotoExplorePage = () => {
    navigate("/explore")
  }
  const gotoMarketplace = () => {
    navigate("/marketplace")
  }

  // BuyButton to ergo Auctions
  const BuyButton = () => (
    <Button 
      onClick={gotoMarketplace} 
      isFullWidth="true"
      colorScheme='blue'
      leftIcon={<MdSailing/>} 
      rightIcon={<MdSailing/>}
      // rounded='full'
      style={{marginTop: '1rem', marginBottom: '0.7rem'}}
      size='lg'
      bg='blue.400'
      color={"white"}
      whiteSpace={"pre-wrap"}
      py={{base: "8", md: "0"}}
      
      _hover={{ bg: 'blue.600' }}
      >
      View our SkyHarbor Listings
    </Button>
  )

  const DarkModeButton = () => (
      <Button 
        onClick={changeTheme} 
        mt="4"
        // style={{position: "absolute", right: 20}}
        isFullWidth
        rounded='full'
        bgColor={!darkMode ? "black" : "white"}
        color={!darkMode ? "white" : "black"}
      >
        {
          !darkMode ? <MoonIcon /> : <SunIcon />
        }
      </Button>
  )

  return (
    <Fragment >
      <h1 style={{display:'none'}}>Ergosaurs</h1>

      

      {/* Dark Mode Button */}
      {/* <DarkModeButton /> */}

      {/* Banner */}
      <HomeHero />
      <BuyButton/>
      {/* <DinoEggs /> */}
      
      <Divider mt="8" mb="8"/>


      <HomeTop />

      <Events />

      <Divider mt="8" mb="8"/>


      <Features />



      
      {/* <Box mt="1" mb="8">

        <RaffleFeature 
          title='The "Win an Ergosaur" Lottery!' 
          link={"https://ergoraffle.com/raffle/show/ad82a9b02b0c7141b8d20f72bc58cc2db72f61ce531c82b30373dccdc3758e05"} 
          description="Can't participate in the auctions? Well, we're announcing the very first raffle on the updated Raffle House! Have a chance at winning an Ergosaur as well as hundreds in ERG. Tickets are available now!"
        />
      </Box> */}

      {/* Ongoing Auctions */}
      {/* <Heading mb="4" mt="6" id={'auctions'}>Current Auctions</Heading> */}
      {/* <Heading style={{marginBottom: 6}} id={'auctions'}>Ergosaurs x Everyone Collection</Heading> */}
      {/* <Text as="h4" size="sm" mb="1">
        Newly released Ergosaurs! To see auctions from Ergosaurs owners go to <span onClick={gotoAuctionsPage} style={{color: "cadetblue"}}>the auctions page</span>
      </Text> ....dont upload this yet*/}
      {/* <AuctionsList /> */}
      {/* <HomeHero /> */}
      <h1 style={{display:'none'}}>Ergosaurs</h1>

      {/* Ongoing Auctions */}
      {/* <Heading mb="1" mt="4" id={'auctions'}>Current Auctions</Heading> */}
      {/* <Heading mb="1" mt="4" id={'auctions'}>Ergosaurs x Everyone Series</Heading> */}
      {/* <Text as="h4" size="sm" mb="1">
        Newly released Ergosaurs! To see auctions from Ergosaurs owners go to <span onClick={gotoAuctionsPage} style={{color: "cadetblue"}}>the auctions page</span>
      </Text> ....dont upload this yet */}
      {/* <AuctionsList /> */}

      {/* <HomeTop /> */}

      

      {/* Rarity */}
      {/* <Heading style={{marginBottom: 6}}>Power Levels</Heading>
      <Text as="h4" size="sm" style={{marginBottom: 20}}>While every Ergosaur is hand-drawn and unique, they do have their own strengths. Their background color shows the power level of each Ergosaur.</Text>
      <ColorList /> */}
      <Divider mt="8" mb="8"/>

      {/* Ergosaurs */}
      <Heading mb="8" mt="2" id={'released'} textAlign="center">Latest Release</Heading>
      <CardList/>

    </Fragment>
  )
}
