import React from 'react'
import {
    Container,
    Stack,
    Flex,
    Image,
  } from '@chakra-ui/react';
  import logoImage from '../../assets/images/Ergosaurslogo.png'
  import logoImageEveryone from '../../assets/images/Ergosaursxeveryone.png'
  import './HomeHero.css';

export const HomeHero = () => {
      return (
      // <Container maxW='7xl'>
        <Stack
          align='center'
          // spacing={{ base: 8, md: 10 }}
          py={{ base: 4, md: 10 }}
          direction={{ base: 'column', md: 'row' }}>
          <Flex
            flex={1}
            justify='center'
            align='center'
            position='relative'
            w='full'>
              <Image
                alt='Hero Image'
                fit='contain'
                align='center'
                w='100%'
                h='100%'
                maxH="80vh"
                // h='80vh'
                src={logoImage}
                className="shadowFilter"
              />
          </Flex>
        </Stack>
      // </Container>
    );
  }
  