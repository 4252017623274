import React, { Fragment } from 'react'
import { useNavigate } from "react-router-dom";
// import Counter from '../../components/counter/Counter'
import {
  Heading,
  Text,
  Button, Tooltip, Box
} from "@chakra-ui/react";
import { FaGavel  } from 'react-icons/fa';
import { MoonIcon, SunIcon } from '@chakra-ui/icons'

import { HomeHero } from '../../components/HomeHero/HomeHero'
import { HomeTop } from '../../components/HomeTop/HomeTop'
// import Features from '../../components/Features/Features'
import ExploreList from '../../components/CardList/ExploreList'
import AuctionsList from '../../components/CardList/AuctionsList'

import ColorList from '../../components/ColorList/ColorList'
import axios from 'axios';

export const ExplorePage = ({changeTheme, darkMode}) => {

  const navigate = useNavigate();

  // If there are no ergosaurs for sale, disables button + tooltip
  const forSale = false;

  const checkArtist = () => {
    window.open("https://twitter.com/ergosaurs/status/1440745330039930883");
  }
  const gotoAuction = () => {
    window.open("https://ergoauctions.org/#/auction/active?type=picture&artist=9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi");
  }
  const gotoAuctionsPage = () => {
    navigate("/auctions")
  }
  const gotoInfo = () => {
    navigate("/info")
  }




  // BuyButton to ergo Auctions
  const BuyButton = () => (
    <Button 
      onClick={gotoAuction} 
      isFullWidth="true"
      colorScheme='blue'
      leftIcon={<FaGavel/>} 
      rightIcon={<FaGavel/>}
      // rounded='full'
      style={{marginTop: '1rem', marginBottom: '0.7rem'}}
      size='lg'
      bg='blue.400'
      disabled={!forSale}
      
      _hover={{ bg: 'blue.600' }}
      >
      Buy on the Ergo Auction House
    </Button>
  )

  const DarkModeButton = () => (
      <Button 
        onClick={changeTheme} 
        mt="4"
        // style={{position: "absolute", right: 20}}
        isFullWidth
        rounded='full'
        bgColor={!darkMode ? "black" : "white"}
        color={!darkMode ? "white" : "black"}
      >
        {
          !darkMode ? <MoonIcon /> : <SunIcon />
        }
      </Button>
  )

  return (
    <Fragment >
      <h1 style={{display:'none'}}>Explore All Ergosaurs</h1>
      {/* <HomeTop /> */}

      {/* Header */}
      <Heading mt={6} mb="6" id={'auctions'}>Explore All Ergosaurs</Heading>
      {/* <Text as="h4" size="sm" style={{marginBottom: 20}}>Search </Text> */}
      {/* <Text as="h4" size="sm"><span style={{fontWeight: "bolder"}}>Ergosaurs Artist Address:</span> 9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi - <span onClick={gotoInfo} style={{color: "gray", cursor: "pointer"}}>click to learn more.</span></Text>
      <Text as="h4" size="sm"><span style={{fontWeight: "bolder"}}>DinoEggs Artist Address:</span> 9ezLzcm8hNnpPtneqmhs429JAi6RcxvYjiNTUiGQbVE9kGj5AWQ - <span onClick={gotoInfo} style={{color: "gray", cursor: "pointer"}}>click to learn more.</span></Text>
      <Text size="sm" style={{marginBottom: 20}}>We will be adding DinoEggs rarity chart and search feature in the future!</Text> */}
      
      <ExploreList/>

    </Fragment>
  )
}
