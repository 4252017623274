import React, { ReactElement } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, SimpleGrid, Icon, Text, Stack, Flex, Button } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons'
import { FcAssistant, FcDonate, FcInTransit,  } from 'react-icons/fc';
import { FaGavel  } from 'react-icons/fa';
import { MdGavel, MdSavedSearch, MdPeople } from 'react-icons/md';
import { FaTwitter, FaDiscord  } from 'react-icons/fa';

import "./Features.css"

const Feature = ({goto, icon, title, text, buttonText, color, hoverColor}) => {
  return (
    <Stack py="4" spacing="4">
      <Flex
        w={16}
        h={16}
        align='center'
        justify='center'
        // color='white'
        // rounded='full'
        rounded="lg"
        // bg='gray.100'
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text >{text}</Text>
      <Button color="white" onClick={goto} bgColor={color} _hover={{ bg: hoverColor}}>
        {buttonText}
      </Button>
    </Stack>
  );
};

const CommunityFeature = ({twitter, discord,twitterIcon, discordIcon, icon, title, text, buttonText}) => {
  return (
    <Stack py="4" spacing="4">
      <Flex
        w={16}
        h={16}
        align='center'
        justify='center'
        // color='white'
        // rounded='full'
        rounded="lg"
        // bg='gray.100'
        mb={1}>
        {icon}
        
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text >{text}</Text>
      <Stack
        spacing={{ base: 2, sm: 3 }}
        direction={{ base: 'row', sm: 'row' }}
        
      >
        <Button w="50%" onClick={twitter} colorScheme="twitter"><FaTwitter /></Button>
        <Button w="50%" onClick={discord} colorScheme="purple"><FaDiscord /></Button>
      </Stack>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  const navigate = useNavigate();
  const iconSize = 16;

  const gotoExplorePage = () => {
    navigate("/explore")
  }
  const gotoMarketplace = () => {
    navigate("/marketplace")
  }
  const gotoTwitter = () => {
    window.open("https://twitter.com/ergosaurs");
  }
  const inviteDiscord = () => {
    window.open("https://discord.gg/KrRfJwGnG4");
  }


  return (
    <Box my={{ base: 10, md: 4 }}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
        <Feature
          goto={gotoMarketplace}
          icon={<Icon as={MdGavel} w={iconSize} h={iconSize} />}
          title='Bid On and Auction Ergosaurs'
          text='You can bid directly on the ergosaurs.io website for a cleaner bidding experience. No fakes or frauds.'
          buttonText='Buy'
          color="blue.400"
          hoverColor="blue.600"
        />
        <Feature
          goto={gotoExplorePage}
          icon={<Icon as={MdSavedSearch} w={iconSize} h={iconSize} />}
          title='Explore Entire Collection'
          text='Using our Ergosaur explorer, you can find our entire collection along with the owner address and NFT information with a link to ErgoTokens. You can also view our SkyHarbor sales on the marketplace page.'
          buttonText='Explore'
          color="red.500"
          hoverColor="red.600"
        />
        <CommunityFeature
          twitter={gotoTwitter}
          twitterIcon={FaTwitter}
          discord={inviteDiscord}
          discordIcon={FaDiscord}
          icon={<Icon as={MdPeople} w={iconSize} h={iconSize} />}
          title='Join One of the Most Exclusive ENFT Ecosystems'
          text='Ergosaurs owners are part of something big. We utilize everything Ergo gives us to make this community exciting, check in on our latest events!'
        />
      </SimpleGrid>
    </Box>
  );
}