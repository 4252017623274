import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Container,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Stack,
    Image,
    IconButton,
    HStack,
    Modal,
    ModalContent,
    ModalOverlay,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Text,
    Icon,
    Progress
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { FaWallet  } from 'react-icons/fa';
import { useToast } from "@chakra-ui/react"
import logoImage from '../../assets/images/Ergosaurslogo.png'
import yoroiWallet from '../../assets/images/yoroi-logo-shape-blue.inline.svg';
import {
    getWalletAddress, getWalletType,
    isAddressValid,
    isAssembler,
    isWalletNode,
    isWalletSaved, isWalletYoroi,
    showMsg
} from '../../auction/helpers';
import {getYoroiAddress, setupYoroi} from "../../auction/yoroiUtils";

export default function Nav({changeTheme, darkMode}) {
    let type = 'assembler';
    let walletSt = 'Configure';
    let userAddr = ''

    const toast = useToast()
    const navigate = useNavigate();


    //For Modal
    const finalRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [processing, setProcessing] = useState(false);
    const [activeTab, setActiveTab] = useState(type);
    const [walletState, setWalletState] = useState(walletSt);
    const [userAddress, setUserAddress] = useState(userAddr);
    const [apiKey, setApiKey] = useState('');

    function gotoHome() {
        navigate("/")
    }
    function gotoAbout() {
        navigate("/info");
    }


    function toggle() {

        let type = 'assembler'
        if (isWalletSaved()) type = getWalletType()


        setActiveTab(type === "nautilus" ? "yoroi" : "assembler");
        setProcessing(false);
        setApiKey('');

        onClose();
    }

    function toggleTab(index) {
        console.log('index', index)
        if(index === 0) {
            setActiveTab('yoroi');
        }
        else {
            setActiveTab('assembler');
        }
    }

    function clearWallet(message = true) {
        sessionStorage.removeItem('wallet');
        localStorage.removeItem('wallet');
        setWalletState('Configure');
        setUserAddress('');
        if (message) {
            showMsg('Successfully cleared wallet info from local storage.');
            toggle();
        }
    }

    async function saveWallet() {
        setProcessing(true);
        if (activeTab === 'assembler') {
            clearWallet(false)
            localStorage.setItem(
                'wallet',
                JSON.stringify({
                    type: activeTab,
                    address: userAddress,
                })
            );
            setUserAddress(userAddress)
            showMsg('Successfully configured the wallet.');
            toggle();
            setWalletState('Update')
        }
        if (activeTab === 'yoroi') {
            // toast({
            //     title: "Currently Not Available",
            //     description: `Yoroi dApp Connector will be available soon, for now use the "Any Wallet" tab."`,
            //     position: "top-right",
            //     status: "error",
            //     duration: 5000,
            //     isClosable: true,
            // });
            setProcessing(true);

            clearWallet(false)
            let res = setupYoroi(true)
            let address = await getYoroiAddress()
            if (res && address) {
                localStorage.setItem(
                    'wallet',
                    JSON.stringify({
                        type: "nautilus", // Nautilus for now
                        address: address,
                    })
                );
                setWalletState('Update')
            }
            setProcessing(false);
            toggle();
            return
        }
    }

    useEffect(() => {
        if (isWalletSaved()){
            const walletType = getWalletType();
            if (walletType === "yoroi") {
                localStorage.removeItem('wallet');
            }
            setWalletState('Update');
        } 
        let tempAddress = ''
        if (isAssembler()) {
            tempAddress = getWalletAddress()
            setUserAddress(tempAddress);
        } 
    }, [])


    return (
        <Fragment>
            <Box>

                <Button 
                    onClick={onOpen}
                    // bgColor={!darkMode ? "blackAlpha.200" : "whiteAlpha.500"}
                    // color={darkMode ? "white" : "black"}
                    // bgColor={!darkMode ? "black" : "white"}
                    // color={!darkMode ? "white" : "black"}
                    colorScheme="blue"
                    bgColor="blue.400"

                    rounded='full'
                    size="lg"
                >
                    <Icon as={FaWallet} boxSize={7} />
                </Button>

                {/* <Menu>
                    <MenuButton
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}>
                    <Button 
                        onClick={onOpen}
                        bgColor={!darkMode ? "grey.900" : "whiteAlpha.400"}
                        color={darkMode ? "white" : "black"}
                        rounded='full'
                        size="lg"
                    >
                        <FaWallet/>
                    </Button>
                    </MenuButton>
                    <MenuList alignItems={'center'}>
                        <br />
                        <Center>
                            <Avatar
                            size={'2xl'}
                            src={'https://avatars.dicebear.com/api/male/username.svg'}
                            />
                        </Center>
                        <br />
                        <Center>
                            <p>Username</p>
                        </Center>
                        <br />
                        <MenuDivider />
                        <MenuItem>Your Servers</MenuItem>
                        <MenuItem>Account Settings</MenuItem>
                        <MenuItem>Logout</MenuItem>
                    </MenuList>
                </Menu> */}

                <Modal finalFocusRef={finalRef} size="xl"  isOpen={isOpen} onClose={toggle} motionPreset="slideInBottom">
                    <ModalOverlay />
                    <ModalContent color="black" overflow="hidden" margin="4">
                        <ModalBody padding="4" >
                            <Tabs isFitted variant="enclosed" defaultIndex={activeTab === 'yoroi' ? 0 : 1} onChange={(index) => toggleTab(index)}>
                                <TabList mb="1em">
                                    <Tab>
                                        {/* <img
                                            style={{height: '20px', width: '20px', marginRight: 4}}
                                            src={yoroiWallet}
                                        /> */}
                                        Nautilus
                                    </Tab>
                                    <Tab>Any Wallet</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <p>Connects to your Nautilus wallet.</p>
                                        {/* <Text color="red.400">dApp Connector is currently not available, please use the "Any Wallet" tab!</Text> */}
                                        <Text color={(walletState === 'Update' && !userAddress) ? 'blue.400' : 'red.400'}>{(walletState === 'Update' && !userAddress) ? 'Nautilus connected.' : 'Nautilus not connected.'}</Text>
                                        {
                                            processing && <Progress size="xs" isIndeterminate />
                                        }
                                        
                                    </TabPanel>

                                    <TabPanel>
                                    <p>
                                        You can use <b>any wallet</b> including <b>Yoroi</b> and <b>Ergo Wallet Android</b> to place bid and start new auctions.
                                    </p>
                                    <p style={{marginTop: 8}}>
                                        This uses the assembler service which is an intermediate
                                        step; you can find out more about it{' '}
                                        <a
                                            style={{color: "cadetblue"}}
                                            target="_blank"
                                            href="https://www.ergoforum.org/t/tx-assembler-service-bypassing-node-requirement-for-dapps/443"
                                        >
                                            here
                                        </a>
                                        . Your funds will be safe, find out more
                                        about how{' '}
                                        <a 
                                            style={{color: "cadetblue"}}
                                            target="_blank"
                                            href="https://www.ergoforum.org/t/some-details-about-ergo-auction-house/428/6">
                                            here
                                        </a>
                                        .
                                    </p>

                                    <FormControl mt="3" id="address" isInvalid={!isAddressValid(userAddress)}>
                                        <FormLabel htmlFor="apiKey">Address</FormLabel>
                                        <Input type="text" name="address" id="address" placeholder="Your ergo address"
                                            value={userAddress}
                                            onChange={(event) =>
                                                setUserAddress(event.target.value)
                                            }
                                            errorBorderColor="crimson"
                                        />
                                        <FormErrorMessage>Invalid ergo address.</FormErrorMessage>
                                        <FormHelperText>
                                            Your funds and winning tokens will be sent to this address. {' '}
                                            <b>Make sure your wallet supports custom tokens!</b> {' '}
                                            Currently, <a style={{color: "cadetblue"}} target="_blank" href="https://github.com/ergoplatform/ergo">Ergo Node</a>, {' '}
                                            <a style={{color: "cadetblue"}} target="_blank" href="https://yoroi-wallet.com/">Yoroi</a>, {' '}
                                            <a style={{color: "cadetblue"}} target="_blank" href="https://github.com/ergoplatform/ergo-wallet-android">Ergo Wallet Android</a>, {' '}
                                            <a style={{color: "cadetblue"}} target="_blank" href="https://github.com/coinbarn/coinbarn-extension">Coinbarn</a> and {' '}
                                            <a style={{color: "cadetblue"}} target="_blank" href="https://ergowallet.io/">Ergo Wallet</a>  support tokens.
                                        </FormHelperText>
                                    </FormControl>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </ModalBody>

                        <ModalFooter>
                            <Button disabled={walletState === 'Configure'} variant="ghost" mr={4} onClick={clearWallet}>
                                Clear Wallet Info
                            </Button>
                            <Button  variant="ghost" mr={4} onClick={toggle}>
                                Close
                            </Button>
                            <Button disabled={(!isAddressValid(userAddress) && activeTab === "assembler")} onClick={saveWallet} colorScheme="blue" size="lg" >{activeTab === 'yoroi' ? 'Connect' : 'Save'}</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </Fragment>
    );
}