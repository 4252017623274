import React, { Fragment } from 'react'
import { useNavigate } from "react-router-dom";
import { Box, SimpleGrid, Wrap, WrapItem, Progress, Text, Button, Heading,
  List, 
  ListItem, 
  ListIcon, 
  Stack,
  OrderedList, 
  UnorderedList,
  Link,
  HStack,
  Image
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import ColorList from '../../components/ColorList/ColorList';
import { scroller } from 'react-scroll'
import { ergosaursAuctions } from '../../auction/consts'
import rarityChart from '../../assets/images/raritychart.png'

export const Info = () => {
  const navigate = useNavigate()

  function gotoHome() {
    navigate("/marketplace")
  }
  function goback() {
    navigate(-1)
  }

  const gotoAuction = () => {
    window.open(ergosaursAuctions);
  }

  const gotoSection = (index) => {
    let indexText = "section" + index;
    scroller.scrollTo(indexText, {
      duration: 500,
      offset: -60, // Scrolls to element + 60 pixels up the page
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Links = [
    {
        title: "Ergosaurs Artist Address",
        page: 0
    },
    {
        title: "What is Ergo?",
        page: 1
    },
    {
        title: "What are Ergosaurs?",
        page: 2
    },
    {
        title: "How do I bid?",
        page: 3
    },
    {
        title: "Important Features",
        page: 4
    },
    {
        title: "Questions or Issues",
        page: 5
    },
    
]

  const NavLink = ({children, page}) => (
    <Link
      px={2}
      py={1}
      onClick={() => gotoSection(page)}
      fontSize={"lg"}
      // fontWeight={"semibold"}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        // bg: 'gray.200',
        color: 'grey'
      }}
      >
      - {children}
    </Link>
  );

  return (
    <Fragment>
      <Button
        // onClick={() => navigate('/')}
        colorScheme="blue"
        // variant="outline"
        // bgColor="blue.400"
        // size="lg"
        rounded='full'
        mt="6"
        onClick={goback}
        style={{position: "sticky", top: 20}}
      >
        <ArrowBackIcon mr="1.5"/>back
      </Button>



      <Box>
        <Box my="8">
          <Text fontWeight={"semibold"} fontSize={"xl"} mb="2">Index</Text>
          <List  fontWeight={"semibold"}>
            {/* <Stack direction={"row"}> */}
            <Stack
            direction={{base: "column", xl:"row"}}
              // as={'nav'}
              spacing={4}
              // alignContent={"center"}
              display={{ base: 'flex', md: 'flex' }}>
              {Links.map((link) => (
                  <NavLink key={link.title} page={link.page}>{link.title}</NavLink>
              ))}
            </Stack>
          </List>
          </Box>

        <Heading mt="4" mb="4" textAlign={"center"} id="section11">DinoEggs Rarity Chart</Heading>
        {/* <Text mb="1" fontSize="lg" >DinoEggs Rar</Text> */}
        <Box mx={"auto"} my={6} maxW={800} borderRadius={8} overflow={"hidden"}>
        <Image alt='Hero Image'
          fit='contain'
          align='center'
          // maxW={'100%'}
          src={rarityChart} />
        </Box>

        <Heading mt="4" mb="3" id="section0">Artist Address</Heading>
        <Text mb="1" fontSize="lg" ><span style={{fontWeight: "bolder"}}>Ergosaurs Artist Address:</span> 9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi</Text>
        <Text mb="3" fontSize="lg" ><span style={{fontWeight: "bolder"}}>DinoEggs Artist Address:</span> 9ezLzcm8hNnpPtneqmhs429JAi6RcxvYjiNTUiGQbVE9kGj5AWQ</Text>
        <Text mb="4" size="sm" >
          <span style={{fontWeight: "bolder"}}>What is an artist address?:</span> An artist address is how you verify if an Ergo NFT [ENFT] is genuine.
          While viewing NFT's on the Ergo Auction House it is important to check if the artist address of the NFT you want to buy matches the one given by the creator. Ours is the one above and is also found on our Twitter page. Our website [ergosaurs.io] filters out NFT's that don't have our artist address, so the ones here are guaranteed to be the real ones.
        </Text>

        {/* <Heading style={{marginBottom: 6}}>Power Levels</Heading>
        <Text as="h4" size="sm" style={{marginBottom: 20}}>While every Ergosaur is hand-drawn and unique, they do have their own strengths. Their background color shows the power level of each Ergosaur.</Text>
        <ColorList /> */}

        <hr />
        <Heading mt="4" style={{marginBottom: 6}} id="section1">What is Ergo?</Heading>
        <Box>
          <Text mb="2">
            "Ergo draws on ten years of blockchain development, complementing tried and tested principles with the best peer-reviewed academic research into cryptography, consensus models and digital currencies."
            -  <a href="https://ergoplatform.org/" style={{color: "#ff4221"}}>Ergo Foundation</a>
          </Text>
          <Text mb="2">
            Ergo is a coin that has implemented all of the latest features blockchain has to offer which allows for radically new functionality.
            <br />
            Words can only go so far though, we suggest checking out the ecosystem for yourself. You can find every Ergo dApp here: <a href="https://sigmaverse.io/" style={{color: "#ff4221"}}>sigmaverse.io</a>
          </Text>
          <Text mb="1">
            Some examples include:
          </Text>
          <UnorderedList>
            <ListItem>Ergo Auction House - View, Auction, or Sell your ENFT's</ListItem>
            <ListItem>Ergo Raffle - Lottery and Charity Raffles</ListItem>
            <ListItem>Ergo Mixer - Sending ERG 100% Privately</ListItem>
            <ListItem>SigmaUSD - Algorithmic Stablecoin</ListItem>
            <ListItem>and more...</ListItem>
          </UnorderedList>
        </Box>

        <Heading mt="6" style={{marginBottom: 6}} id="section2">What are Ergosaurs?</Heading>
        <Text>
          Ergosaurs are a series of hand-drawn collectible NFT's, 1 out of 1 dinosaurs.
          Once every 1-2 weeks, a wave of Ergosaurs are dropped into the auction house so you and the community can bid on them. 
          We also have events and collaborations where we release special Ergosaurs in a unique way using the Ergo ecosystem.
          Then, you can buy, sell, and trade with other Ergosaur owners to collect your favorites!
        </Text>
        <Text mt="2" fontWeight="semibold">
          Every Ergosaur is minted and stored permanently on the Ergo Blockchain.
        </Text>      

        <Heading mt="6" style={{marginBottom: 6}} id="section3">How do I bid?</Heading>
        <Box>
          <Text mb="1" fontSize="2xl" fontWeight="semibold">Any Wallet</Text>
          To participate in the bidding, follow these steps:
          <OrderedList mt="1">
            <ListItem>Set up an Ergo Wallet. This wallet must also support custom tokens. Currently <a style={{color: "cadetblue"}} target="_blank" href="https://github.com/ergoplatform/ergo">Ergo Node</a>, {' '}
              <a style={{color: "cadetblue"}} target="_blank" href="https://yoroi-wallet.com/">Yoroi</a>, {' '}
              <a style={{color: "cadetblue"}} target="_blank" href="https://github.com/ergoplatform/ergo-wallet-android">Ergo Wallet Android</a>, {' '}
              <a style={{color: "cadetblue"}} target="_blank" href="https://github.com/coinbarn/coinbarn-extension">Coinbarn</a> and {' '}
              <a style={{color: "cadetblue"}} target="_blank" href="https://ergowallet.io/">Ergo Wallet</a> support tokens. We suggest Yoroi for non-mobile and Ergo Wallet Android if you have an Android.</ListItem>
            <ListItem>Copy your recieving address from your Ergo wallet, then go to Ergosaurs.io (or <Box as="span" color="orange.400" style={{cursor: "pointer"}} onClick={gotoAuction}>Ergo Auction House</Box>) and click on the wallet icon at the top-right of the site.</ListItem>
            <ListItem>Paste your Ergo wallet address and click save.</ListItem>
            <ListItem>When you want to bid on an Ergosaur, click on "Place Bid" on the desired one.</ListItem>
            <ListItem>Type in the amount of ERG you want to bid. Click "Bid for XX ERG" then wait for it to load.</ListItem>
            <ListItem>Follow the instructions on the following page to finalize your bid! It should say something like "Send exactly XX.001 ERG to this address: XXXXXXXXXX...XXXXXXXXXX"</ListItem>
            <ListItem><Box as="span" fontWeight="bold" onClick={gotoAuction}>Wait.</Box> The Ergo Blockchain is confirming your transaction, this should take 1-10 minutes. We suggest bidding at least 15 minutes before the end of an auction to make sure your transaction goes through.</ListItem>
          </OrderedList>
        </Box>
        <Box mt="4">
          <Text mb="1" fontSize="2xl" fontWeight="semibold">Yoroi Nightly</Text>
          If you have Yoroi Nightly and Yoroi Nightly dApp Connector, just connect your wallet and start bidding!
          <Text>ErgoDex came out with a great tutorial on how to install them. You can check it out <a target="_blank" style={{color: "cadetblue"}} href="https://docs.ergodex.io/docs/user-guides/quick-start/">here.</a></Text>
        </Box>

        <Heading mt="6" style={{marginBottom: 6}} id="section4">Important Features</Heading>
        <Box>
          We think the auction medium is an exciting way to bring the community together. If this is your first time auctioning for ENFT's these are important things to know:
          <UnorderedList mt="1">
            <ListItem>Auctions auto-extend by 1 hour whenever a new bid is placed in the final hour.</ListItem>
            <ListItem>Ergo V2 NFT's include artist royalties. This means whenever you sell an NFT, a small percentage (3-5%) of the ERG will go to the artist who worked so hard to bring you your valued NFT!</ListItem>
          </UnorderedList>
        </Box>
          
        <Box mt="2">
          In addition, the Ergosaurs website has:
          <UnorderedList mt="1">
            <ListItem>Popup toast whenever a new bid comes in.</ListItem>
            <ListItem>Tiny easter eggs based off ERG prices.</ListItem>
            <ListItem>Dark mode!</ListItem>
          </UnorderedList>
        </Box>

        <Heading mt="6" style={{marginBottom: 6}} id="section5">Questions or Issues</Heading>
        <Text mt="2">
          If you have any questions, you can DM us on <a style={{color: "cadetblue"}} target="_blank" href="https://twitter.com/ergosaurs">Twitter</a>. Once more Ergosaurs are in circulation, we will be creating a Discord.
        </Text>
        <Text mt="2">
          If you have any issues, don't worry, your funds are safe. Ergosaurs.io utilizes the auction house's <Box as="span" fontWeight="bold">smart contracts</Box> to handle all bids. 
          If your bid doesn't go through they will be returned eventually. The answer is usually to just wait. 
          If you have waited a long time (more than 6 hours), copy the address you sent the funds to for your bid and go to the "Refund" tab at the <Box as="span" color="orange.400" style={{cursor: "pointer"}} onClick={gotoAuction}>Ergo Auction House</Box>.
        </Text>
        <Text color="red.400">*Note*: It is important you send *only the amount required* and no more. If you send more than the amount specified, the extra amount might be gone for good.</Text>

        <Box display="flex" justifyContent="center">
          <Button
            // onClick={() => navigate('/')}
            colorScheme="blue"
            bgColor="blue.400"
            mt="6"
            alignSelf="center"
            size="lg"
            onClick={gotoHome}
          >
            Start Bidding
          </Button>
        </Box>
      </Box>
    </Fragment>
  )
}
