import React, { useEffect } from 'react'

import { Box, SimpleGrid, Wrap, WrapItem, Progress, Text, Button } from '@chakra-ui/react';
// import { collection, addDoc } from "firebase/firestore"; 
// import { db } from '../../utils/firebase';

import NewErgosaursCard from '../../components/Card/NewErgosaursCard';
import {
    decodeBoxes,
    longToCurrency
} from '../../auction/serializer';


export default function ResaleAuctions ({auctions, toggleAssemblerModal}) {

    if(auctions.length === 0) {
        return (
            <Box mb="4">
                <Text mb="1" mt="6" fontSize="2xl" >NFT Resale Auctions</Text>
                <Text mb="6"  fontSize="md" >These are the NFT's being resold by the owners. Includes both Ergosaurs and DinoEggs.</Text>

                <Box
                    color="gray.500"
                    letterSpacing="wide"
                    fontSize="md"
                    mt="2"
                >
                    There are no new ergosaurs currently for auction. Check back later!
                </Box>
            </Box>
        )
    }


    return (
        <React.Fragment>
            <Text mb="1" mt="6" fontSize="2xl" >NFT Resale Auctions</Text>
            <Text mb="6"  fontSize="md" >These are the NFT's being resold by the owners. Includes both Ergosaurs and DinoEggs.</Text>

            <SimpleGrid columns={{ base: 1, sm:1, md: 2, lg: 3, xl: 4 }} spacing={10}>
            {/* <Wrap spacing="30px" justify="center"> */}

                {
                    auctions.map(item => {
                        return (
                            <React.Fragment key={item.NFTID}>
                                {/* <Text>{item.tokenName}</Text> */}
                                
                                <NewErgosaursCard box={item} auction={true} 
                                    assemblerModal={toggleAssemblerModal}
                                />
                            </React.Fragment>
                        )
                    })
                }
                </SimpleGrid>

            {/* <Text>Brand spankin new Ergosaurs!</Text> */}
        </React.Fragment>
    )
}
