import React, { Fragment } from 'react'
// import Counter from '../../components/counter/Counter'
import {
  Heading,
  Text,
  Button, Tooltip, Box
} from "@chakra-ui/react";
import axios from 'axios';
import { FaGavel  } from 'react-icons/fa';
import { MoonIcon, SunIcon } from '@chakra-ui/icons'

import { HomeHero } from '../../components/HomeHero/HomeHero'
import { HomeTop } from '../../components/HomeTop/HomeTop'
// import Features from '../../components/Features/Features'
import CardList from '../../components/CardList/CardList'
import AuctionsWrapper from './AuctionsWrapper'
import CreateAuctionButton from './CreateAuctionButton';
// import SkyHarborSales from './SkyHarborSales';

export const AuctionsPage = ({changeTheme, darkMode}) => {

  // If there are no ergosaurs for sale, disables button + tooltip
  const forSale = false;

  const checkArtist = () => {
    window.open("https://twitter.com/ergosaurs/status/1440745330039930883");
  }
  const gotoAuction = () => {
    window.open("https://ergoauctions.org/#/auction/active?type=picture&artist=9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi");
  }
  

  // BuyButton to ergo Auctions
  const BuyButton = () => (
    <Button 
      onClick={gotoAuction} 
      isFullWidth="true"
      colorScheme='blue'
      leftIcon={<FaGavel/>} 
      rightIcon={<FaGavel/>}
      // rounded='full'
      style={{marginTop: '1rem', marginBottom: '0.7rem'}}
      size='lg'
      bg='blue.400'
      disabled={!forSale}
      
      _hover={{ bg: 'blue.600' }}
      >
      Buy on the Ergo Auction House
    </Button>
  )

  return (
    <Fragment >

      {/* Banner */}
      {/* <HomeHero /> */}
      <h1 style={{display:'none'}}>Ergosaurs</h1>

      {/* Ergosaurs Introduction */}
      {/* <HomeTop /> */}

      {/* Ongoing Auctions */}
      <Heading letterSpacing={"wide"} textAlign={"center"} mb="4" mt="6" id={'auctions'} >Ergosaurs Auctions</Heading>
      {/* <Box m="4" textAlign={"center"} height={"40px"}>
        <CreateAuctionButton />
      </Box> */}
      {/* <SkyHarborSales/> */}
      <AuctionsWrapper />


    </Fragment>
  )
}
