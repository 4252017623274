import { CheckCircleIcon } from '@chakra-ui/icons'
import { Box, Heading, HStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import DinoEggs from '../../components/DinoEggs/DinoEggs'

export default function DinoEggsPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Box mt="14">

            <HStack justifyContent={"center"}  mb="8">
                <CheckCircleIcon color="green.400" fontSize={"3xl"}/> 
                <Heading textAlign={"center"} >
                    Event Finished
                </Heading>
                <CheckCircleIcon color="green.400" fontSize={"3xl"}/>
            </HStack>

            <DinoEggs/>
        </Box>
    )
}
