// Sample card from Airbnb
import React from "react";
import {
    Box,
    Badge,
    Image,
    Tooltip,
    Button,
    useDisclosure,
    Modal,
    ModalContent,
    ModalCloseButton,
    ModalOverlay,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Text,
    InputGroup,
    InputRightAddon,
    AspectRatio
} from "@chakra-ui/react"
import axios from "axios";
import Countdown from 'react-countdown';
import { InfoIcon } from "@chakra-ui/icons";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react"
import ModalInfo from './components/ModalInfo'
import { friendlyERG, friendlyToken, isWalletSaved, showMsg } from '../../auction/helpers';
import {currencyToLong, isFloat, longToCurrency} from '../../auction/serializer';
import {bidHelper} from "../../auction/newBidAssm";
import {supportedCurrencies} from "../../auction/consts";

import "./Card.css"
import logoImage from '../../assets/images/Ergosaurslogo.png'
import loadingGif from '../../assets/images/loading.gif'

// If Auction Card these are properties
//
//    auction={true}
//    title
//    imageUrl
//    description
//    value - diff
//    link
//    tokenId
//    percentIncrease - diff

// If Normal Card these are properties
//
//    auction={false}   
//    title
//    imageUrl
//    description
//    sold - diff
//    link
//    tokenId
//    soldFor - diff
//    new - diff

// Countdown timer custom component
const Completionist = () => <span>Auction Ended!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span> Ends in {days} day{days !== 1 && 's'} {hours} hour{hours !== 1 && 's'} and {minutes} minute{minutes !== 1 && 's'}.</span>;
  }
};

export const Card = (props) => {    
    const toast = useToast()
    const [currentOwner, setCurrentOwner] = React.useState("");
    const [modalType, setModalType] = React.useState("auction");
    const [bidAmount, setBidAmount] = React.useState(props.auction ? longToCurrency(props.nextBid, -1, props.box.currency) : 0);
    const [modalLoading, setModalLoading] = React.useState(0);

    // const formatValue = friendlyERG(props.value);
    const formatValue = props.auction ? longToCurrency(props.box.curBid, -1, props.box.currency) : 0;
    // console.log(props.box.curBid)
    // console.log(props.box.nextBid)
    // console.log(props.box.currency)

    // the higher the bid, the larger the number
    const percentSize = (props.value / 1e9)/4 + 10;
    let cancel1;
    let cancel2;

    //For Modal
    const finalRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()


    const handleLink = () => {
      window.open(`https://skyharbor.io/token/${props.tokenId}`)
    }

    const gotoAuction = () => {
      window.open("https://ergoauctions.org/#/auction/active?type=picture&artist=9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi");
    }

    const handleBidButton = () => {
      setModalType("bid");
      onOpen()
    }
    const handleAuctionButton = () => {
      setModalType("auction");
      onOpen()
    }

    const handleCopyAddress = (text) => {
      navigator.clipboard.writeText(text).then(()=> toast({
        title: "Copied Address",
        // description: "We've created your account for you.",
        position: "top-right",
        status: "info",
        duration: 5000,
        isClosable: true,
      }))
    }

    // Place Bid function
    function placeBid() {
      if (!isWalletSaved()) {
          showMsg(`Please configure the wallet first!`, true);
          return;
      }
      setModalLoading(true)
      const bidA = currencyToLong(bidAmount, supportedCurrencies[props.box.currency].decimal, props.box.currency)
      bidHelper(bidA, props.box, props.assemblerModal)
          .finally((_) => {
              onClose()
              setModalLoading(false)
          });
    }

    // Get already sold token info
    const getTokenInfo = async () => {
      axios.get(`https://api.ergoplatform.com/api/v1/boxes/byTokenId/${props.tokenId}`, {
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel1 = c;
        })
      })
        .then(function (response) {

          // Go to latest wallet transaction #
          const numWallets = response.data.total - 1;

          // Call api to get the latest wallet transaction
          axios.get(`https://api.ergoplatform.com/api/v1/boxes/byTokenId/${props.tokenId}?offset=${numWallets}`, {
            cancelToken: new axios.CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel2 = c;
            })
          })
          .then (response2 => {
						if (response2.data.items[0].address !== undefined){
              setCurrentOwner(response2.data.items[0].address)
              // console.log(props.title, response2);
						}
					}).catch(function (error) {
            // handle error
            // console.log(error);
          })

        })
        .catch(function (error) {
          // handle error
          // console.log(error);
        })
    }

    React.useEffect(()=> {
      if (!props.auction) {
        getTokenInfo();
      }

      return () => {
        // Cancel get requests
        if (!props.auction && cancel1 ) {
          if(cancel1) {
            cancel1();
          }
          if(cancel2) {
            cancel2();
          }
        }
      }
    }, [])
  
    return (
      <Box 
        borderRadius="2xl" 
        overflow="hidden" 
        bg="white" 
        color="black"  
        style={{boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)", overflow: "hidden"}}
      >
        <div className="zoomContainer">
          <AspectRatio ratio={1}>

            <Image className="zoom" 
              // height={props.auction ? 350 : 200} 
              width="100%" 
              objectFit="cover" 
              src={props.imageUrl} 
              alt={props.title} 
              onClick={props.auction ? handleAuctionButton : handleLink}
              fallbackSrc={loadingGif}
              bg="white"
            />
          </AspectRatio>

        </div>
  
        <Box p="6">
          <Box d="flex" alignItems="baseline" marginBottom={2}>
            {
              props.new && 
              <Badge borderRadius="full" px="2" colorScheme="teal">
                New
              </Badge>
            }
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml={props.new ? "2" : "0"}
            >
              Ergosaurs {props.royalty ? ` - ${props.royalty/10}% royalty` : ""}
            </Box>
          </Box>
  
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {props.title}
          </Box>

          <Box
            mt="1"
            mb="4"
            fontWeight="normal"
            as="p"
            lineHeight="tight"
            // isTruncated
            noOfLines={2}
          >
            {props.auction ? props.auctionDescription : props.description}
          </Box>
          <hr />
  
          {/* Not for Auction */}
          {
            !props.auction &&
            <Box>
              <Box d="flex" mt="3" alignItems="center" onClick={handleLink} style={{cursor: "pointer"}}>
                {props.sold ? <InfoIcon color="yellow.400"/> : <CheckCircleIcon color="green.400"/>}
                <Box as="span" ml="1" color="gray.600" fontSize="sm">
                  {props.sold ? `${props.soldFor ? `Sold for ${props.soldFor} ERG` : "Sold: Giveaway"}` : "Available"}
                </Box>
              </Box>
              {props.sold &&
                <Tooltip label="Click to copy address">
                  <Box mt="1" color="gray.600" fontSize="sm" d="flex" onClick={() => handleCopyAddress(currentOwner)}>
                    Owner:
                      <Box style={{marginLeft: 4}} noOfLines={1} >
                        {currentOwner ? currentOwner : "loading..."}
                      </Box>
                  </Box>
                </Tooltip>
              }
            </Box>
          }


          {props.value && 
            <Box>
              <Box mt="2" color="gray.600" fontSize="md" d="flex" alignItems="baseline" onClick={() => handleCopyAddress(currentOwner)}>
                Current Bid: {props.seller != props.bidder ? `${formatValue} ${props.box.currency}` : "none"}
                <Box color="green.300" ml="1" style={{fontSize:(percentSize) > 20 ? 20 : percentSize}}>
                  {props.percentIncrease}%^
                </Box>
              </Box>

              <Box color="gray.600" fontSize="md" d="flex" alignItems="baseline" onClick={() => handleCopyAddress(currentOwner)}>
                Minimum Bid: {longToCurrency(props.nextBid, -1, props.box.currency)}{' '}{props.box.currency}
              </Box>


              <Box mt="2" fontFamily="inherit"  color="gray.600" fontSize="sm" d="flex">
                Auction ends in {props.remTime}
                {/* <Countdown date={new Date(props.endTime)} 
                      renderer={renderer}
                /> */}
              </Box>
              <Button 
                onClick={gotoAuction} 
                onClick={handleBidButton}
                isFullWidth="true"
                colorScheme='blue'
                // leftIcon={<FaGavel/>} 
                // rightIcon={<FaGavel/>}
                // rounded='full'
                style={{marginTop: '1rem', marginBottom: '0.7rem'}}
                size='lg'
                bg='blue.400'
                // disabled={!forSale}
                
                _hover={{ bg: 'blue.600' }}
                >
                Bid for {longToCurrency(props.nextBid, -1, props.box.currency)}{' '}{props.box.currency}
              </Button>
            </Box>
          }
          

          
        </Box>

        {/* ----- NFT Modal ----- */}

        <Modal finalFocusRef={finalRef} size="xl" isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
          <ModalOverlay />
          {
            modalType === 'bid' ? 
            <ModalContent color="black" overflow="hidden" margin="4">
              <ModalHeader paddingBottom="2">New bid for {props.title}</ModalHeader>
              <ModalBody >
                {/* Bid Image */}
                <Box borderRadius="6"  padding={0} overflow="hidden">
                  <Image height="200px" width="100%" objectPosition="center" objectFit="cover" src={props.imageUrl} alt={props.title}/>
                </Box>

                {/* Bidding info and input */}
                <Text mb="0" mt="2" fontWeight="semibold" fontSize="xl">Current Bid: { props.seller != props.bidder ? `${formatValue} ${props.box.currency}` : "none"}</Text>
                <Text mb="4">Minimum Bid Allowed: {longToCurrency(props.nextBid, -1, props.box.currency)}{' '}{props.box.currency}</Text>
                <FormControl id="bid">
                  <FormLabel htmlFor="apiKey">Bid Amount</FormLabel>
                    <InputGroup>
                      <Input type="text" name="address" id="address" placeholder={`Amount in ${props.box.currency}`}
                          isInvalid={
                            currencyToLong(bidAmount, supportedCurrencies[props.box.currency].decimal, props.box.currency) < props.nextBid
                          }
                          value={bidAmount}
                          onChange={(e) => {
                            if (isFloat(e.target.value)) {
                                setBidAmount(e.target.value);
                            }
                          }}
                          errorBorderColor="crimson"
                      />
                      <InputRightAddon children={`${props.box.currency}`} />
                    </InputGroup>
                  <FormErrorMessage>Invalid amount.</FormErrorMessage>
                  {
                    (((((bidAmount - longToCurrency(props.curBid, -1, props.box.currency))/longToCurrency(props.curBid, -1, props.box.currency)) * 100) > 100) && props.seller != props.bidder) &&
                    <FormHelperText color="green.400">👀{' '}{(((bidAmount - longToCurrency(props.curBid, -1, props.box.currency))/longToCurrency(props.curBid, -1, props.box.currency)) * 100).toFixed(0)}% bid increase 🎉</FormHelperText>
                  }
                  <FormHelperText>Specify your bid amount.</FormHelperText>
                  <FormHelperText>Ergo supports a maximum of 9 decimal places.</FormHelperText>
                </FormControl>

                <ModalFooter mt="4">
                  <Button  variant="ghost" mr={4} onClick={onClose}>
                    Close
                  </Button>
                  <Button 
                    colorScheme="blue" 
                    size="lg"
                    disabled={
                      currencyToLong(bidAmount, supportedCurrencies[props.box.currency].decimal, props.box.currency) <
                      props.nextBid || modalLoading
                    }
                    onClick={placeBid}
                  >
                    Bid for {bidAmount ? bidAmount : longToCurrency(props.nextBid, -1, props.box.currency)} {props.box.currency}
                  </Button>
                </ModalFooter>
              </ModalBody>
            </ModalContent>

            :
            <ModalContent color="black" overflow="hidden" margin="4">
              <ModalCloseButton color="white" bgColor="blue.400" _hover={{bgColor: "red"}}/>
              <ModalBody padding="0" >
                <ModalInfo 
                  imgUrl={props.imageUrl}
                  description={props.auction ? props.auctionDescription : props.description}
                  title={props.title}
                  artist={props.artist}
                  // policyId={property.policyId}
                  value={props.value}
                  nextBid={ (props.nextBid / 1e9).toFixed(2)}
                  minBid={ (props.minBid / 1e9).toFixed(2)}
                  percentIncrease={props.percentIncrease}
                  endTime={props.endTime}
                  remTime={props.remTime}
                  seller={props.seller}
                  bidder={props.bidder}
                  box={props.box}
                />
              </ModalBody>

              <ModalFooter>
                <Button  variant="ghost" mr={4} onClick={onClose}>
                  Close
                </Button>
                <Button  variant="ghost" mr={4} onClick={gotoAuction}>
                  Auction House
                </Button>
                {props.auction && <Button colorScheme="blue" size="lg" onClick={handleBidButton}>Bid for {longToCurrency(props.nextBid, -1, props.box.currency)}{` `}{props.box.currency}</Button>}
              </ModalFooter>
            </ModalContent>
          }
        </Modal>

      </Box>
    )
  }

export default Card;
