import React, { Fragment } from 'react'
import { useNavigate } from "react-router-dom";
// import Counter from '../../components/counter/Counter'

import { collection, getDocs,doc,  query, orderBy, where, limit } from "firebase/firestore"; 
import { db } from '../../utils/firebase';

import {
  Heading,
  Text,
  Stack,
  HStack,
  Flex,
  Button, ListIcon, Box, Image, ListItem, List,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Divider,
  Progress,
  Icon

} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react"
import { friendlyAddress } from '../../auction/helpers'
import { BsEgg, BsFillEggFill  } from 'react-icons/bs';
import egggif from '../../assets/images/egggif.gif'
import dinoEggsCover from '../../assets/images/dinoeggscover.png'
import dinEgg1 from '../../assets/images/0023.png'
import dinEgg2 from '../../assets/images/0493.png'
import QRCode from "react-qr-code";
import axios from "axios";
import RarityButton from './RarityButton';
import { FaBurn } from 'react-icons/fa';

export default function DinoEggs() {
  const navigate = useNavigate()
    const finalRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()

    const [amountLeft, setAmountLeft] = React.useState();
    const [disableEgg, setDisableEgg] = React.useState(true);

    const ergToNanoErg = 1000000000 
    
    const eggAddress = "9ezLzcm8hNnpPtneqmhs429JAi6RcxvYjiNTUiGQbVE9kGj5AWQ";

    const gotoMarketplace = () => {
      navigate("marketplace")
    }
    const gotoAuction = () => {
      navigate("auctions")
    }

    const handleCopyAddress = (text, message = "Copied to clipboard") => {
      navigator.clipboard.writeText(text).then(()=> toast({
        title: message,
        // description: "We've created your account for you.",
        position: "top-right",
        status: "info",
        duration: 5000,
        isClosable: true,
      }))
    }

    const isItTime = () => {
      let obj = {};
      
      const q = query(collection(db, "event"), limit(1));

      getDocs(q)
        .then((querySnapshot)=> {
            querySnapshot.forEach((doc) => {
                // console.log(`${doc.id} => ${doc.data()}`);
                console.log(doc.data());
                obj = doc.data();
            });

            console.log(obj)

            if (obj.open === true) {
              setDisableEgg(false)
            }
        });
    }

    const getAmountLeft = () => {
      axios.get("https://ergonaut.io/api/checkUnsold").then(res => {
        // console.log(res.data.hello)
        setAmountLeft(res.data.count)
        // setAmountLeft(340)
      }).catch(err => console.error(err));

    }
    // axios.get("https://ergonaut.io/api/checkUnsold").then(res => {



    React.useEffect(()=> {
      // Get NFT left count
      getAmountLeft();

      isItTime();

      const refreshTimer = setInterval(() => {
        getAmountLeft()
      }, 10000);

      return () => {
          if (refreshTimer !== undefined) {
              clearInterval(refreshTimer);
          }
      }
    }, [])


    // Calculate Egg Price
    let eggPrice = 0;
    if(amountLeft > 500) {
      eggPrice = 5;
    }else if(amountLeft > 450) {
      eggPrice = 6;
    }else if(amountLeft > 400) {
      eggPrice = 7;
    }else if(amountLeft > 350) {
      eggPrice = 8;
    }else if(amountLeft > 300) {
      eggPrice = 9;
    }else if(amountLeft > 250) {
      eggPrice = 10;
    }else if(amountLeft > 200) {
      eggPrice = 11;
    }else if(amountLeft > 150) {
      eggPrice = 12;
    }else if(amountLeft > 100) {
      eggPrice = 13;
    }else if(amountLeft > 50) {
      eggPrice = 14;
    }else if(amountLeft > 0) {
      eggPrice = 15;
    }
    ///
    

    return (
      <Box>
        <Flex direction={{ base: 'column', lg: 'row' }} mt="6" mb="12" >
          <Box textAlign={"center"} mr={{ base: '0', lg: '12' }} 
          alignSelf='center'
            // align='center'
            position='relative'>


            <Flex
            
            // display={{base: 'inherit', lg: "inherit"}}
            direction={{ base: 'row', lg: 'column' }} 
            // maxWidth={400}
            borderRadius={6}
            overflow="hidden"
            >
                  <Image
                  // borderBottomRightRadius={{ base: '0', md: 20 }}
                  // borderTopLeftRadius={{ base: 20, md: '0' }}
                  // borderTopRightRadius={20}
                  width={{ base: '50%' , lg: 300}} 
                  // borderTopRadius={6}
                  alt='Hero Image'
                  fit='contain'
                  align='center'
                  maxW={'100%'}
                  src={dinEgg1}
                  />
                  <Image
                  // display={{ base: 'none', lg: 'inherit' }}
                  // borderBottomRightRadius={{ base: '0', md: 20 }}
                  // borderTopLeftRadius={{ base: 20, md: '0' }}
                  // borderTopRightRadius={20}
                  width={{ base: '50%' , lg: 300}} 

                  // borderBottomRadius={6}
                  alt='Hero Image'
                  fit='contain'
                  align='center'
                  maxW={'100%'}
                  src={dinEgg2}
                  />
            </Flex>
            <Box mt="4">
              <RarityButton />
            </Box>
          </Box>

        <Stack flex={1} spacing={{ base: 4, lg: 4 }} color="black" borderWidth={1} borderColor="gray.300" bgColor="white" borderRadius={8} p="8" boxShadow={"xl"}>
          <Heading
            mt="4"
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '4xl', sm: '5xl', lg: '5xl' }}>
            <Text color='black.500'
            position={'relative'}
            textAlign="center"
            >
              DinoEggs Series by Ergosaurs
            </Text>
          </Heading>
          <Stack flex={1} spacing={{ base: 1}}>
            <Text color='black.500' fontSize='x-large' fontWeight={500}>
              Join the community, trade, and show off your eggs!
            </Text>
            <Box fontSize="xl">
                <Text >
                    598 Dinosaur Eggs randomly generated so each and every one is unique. Collect, trade, or buy and sell on our own private section of the auction house, the first of its kind. Join the Ergosaurs community and spread the Ergo word!
                </Text>
                <List  mt="4" mb="4" fontWeight={"500"}>
                    <ListItem mb="3" >
                        <HStack>
                            <ListIcon fontSize={"xl"} as={BsEgg} color='green.500' />
                            <Box>
                                Gain access to use of our private auction house exclusive to Ergosaurs and DinoEggs.
                            </Box>
                        </HStack>

                    </ListItem> 
                    <ListItem mb="3">
                        <HStack>
                            <ListIcon fontSize={"xl"} as={BsEgg} color='blue.500' />
                            <Box>
                                Be part of future events involving DinoEggs. We will be doing raffle airdrops to lucky egg owners as well as community games.
                            </Box>
                        </HStack>
                    </ListItem>
                    <ListItem mb="3">
                        <HStack>
                            <ListIcon fontSize={"xl"} as={BsEgg} color='red.500' />
                            <Box>
                                DinoEggs are the first of Ergosaurs community drops, but they will be rarer than future drops as Ergo's ecosystem grows in size.
                            </Box>
                        </HStack>
                    </ListItem>
                </List>
            </Box>
            <Divider/>
            <Box>
              <Text mb="2" fontSize={"xl"} fontWeight={"semibold"}>Mint Address: 9ezLzcm8hNnpPtneqmhs429JAi6RcxvYjiNTUiGQbVE9kGj5AWQ</Text>
              <Text fontWeight={"semibold"}>Instructions:</Text>
              <Text>- Click the Buy button below when it is enabled</Text>
              <Text>- Send the amount of ERG displayed to the address given (click the values to copy them)</Text>
              <Text>- Wait until the NFT shows up in your wallet. Blockchain confirmation may take up to 20 minutes.</Text>
              <Text>- If you want to buy more than 1 DinoEgg, you will have to send multiple transactions with the amount of ERG given, there are no "bulk buys"</Text>
            </Box>
            <Box textAlign={"center"}>
              <Button
                mt="4"
                isFullWidth
                onClick={onOpen}
                fontSize={24}
                size='lg'
                h={16}
                px={12}
                colorScheme="red"
                disabled={true}
                leftIcon={<FaBurn />}
                >
                Burned 
              </Button>
              
              <Box textAlign={"center"} mt="2">
                {/* {
                  !disableEgg && 
                  <Box mt="4">
                    <Text fontWeight={"semibold"} fontSize={"xl"}>{amountLeft ? amountLeft : "..."} DinoEggs Left </Text>
                    <Progress mt={3} mx={"auto"} w={300} value={((550 - amountLeft)/550)*100} colorScheme={"red"}/>
                    <Text mt="1"  fontSize='md' fontWeight={"semibold"}>
                    {Math.round(((550 - amountLeft)/550)*100)}% sold                      
                    </Text>
                  </Box>
                } */}
                    <Text fontWeight={"semibold"} fontSize={"xl"}>Sold Out </Text>

              </Box>
              
              <Box mt="4">
                <Button variant={"outline"} mr="4" size="md" onClick={()=>window.open("https://www.skyharbor.io/collection/ergosaurs")}>View on SkyHarbor</Button>

                <Button
                
                  // isFullWidth
                  onClick={gotoAuction}
                  // fontSize={24}
                  size='md'
                  // h={16}
                  // px={12}
                  // colorScheme="blue"
                  variant={"outline"}
                  // disabled={disableEgg}
                  >
                  Buy via Auction
                </Button>

              </Box>
            </Box>
          </Stack>
        </Stack>
        </Flex>
        <Box>
              {/* Pricing: */}
              <Text textDecor={"underline"} fontSize={"2xl"} textAlign={"center"}>Pricing</Text>
              <Box>

                <Text fontSize={"xl"} textAlign={"center"}>For every 50 DinoEggs sold, 1 ERG will be added to the price. First 50 eggs start at 5 ERG each.</Text>
                <Text fontSize={"xl"} textAlign={"center"}>When released, the displayed price of DinoEggs will be updated based on how many are sold.</Text>
              </Box>
            </Box>


        

        {/* ----- NFT Modal ----- */}

        <Modal finalFocusRef={finalRef} size="xl" isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
          <ModalOverlay />
            <ModalContent color="black" overflow="hidden" margin="4">
              {/* <ModalHeader>Send ERG to recieve your DinoEgg</ModalHeader> */}
              <Box  marginLeft="8" marginRight="8">
                {/* <Box margin={"auto"} height={150} borderRadius={8} overflow={"hidden"}> */}

                <Image 
                    // style={{objectPosition: "0px -70px"}}
                    mt="6"
                    mb="2"
                    alt='Hero Image'
                    fit='contain'
                    align='center'
                    // maxW={'100%'}
                    src={dinoEggsCover}                
                />
                </Box>
                <Divider />
              {/* </Box> */}
              {/* <ModalCloseButton color="white" bgColor="blue.400" _hover={{bgColor: "red"}}/> */}
              <ModalBody>
                <Box textAlign={"center"}>
                  <Text fontSize="2xl" >Send ERG to recieve your DinoEgg</Text>
                  <Text fontSize="xl" mb="3" >Click on values to copy</Text>
                    <Text fontSize={"lg"} color="gray.600">Send this amount of ERG:</Text>
                    {amountLeft && <Text color="blue.500" fontWeight={"semibold"}  mb="3" fontSize={"2xl"} style={{cursor: "pointer"}} onClick={() => handleCopyAddress(eggPrice, "Copied ERG amount")}>{eggPrice} ERG</Text>}
                    <Text fontSize={"lg"} color="gray.600">To this address:</Text>
                    <Text color="blue.500" fontWeight={"semibold"}  mb="6" style={{cursor: "pointer"}} fontSize={"2xl"} onClick={() => handleCopyAddress(eggAddress, "Copied ERG address")}>{friendlyAddress(eggAddress)}</Text>
                    <Box textAlign={"center"}>
                      <Box w={200} margin="auto">
                        <QRCode                        
                          size={200}
                          value={"https://explorer.ergoplatform.com/payment-request?address=" + eggAddress +
                          "&amount=" + eggPrice}/>
                      </Box>
                    </Box>
                    <Text mt="4">To view your new DinoEgg after you recieve it, view your wallet on Ergo Auctions. It may take up to 20 minutes for the NFT to show up in your wallet.</Text>
                </Box>
              </ModalBody>

              <ModalFooter>
                <Button mr="4" size="lg" onClick={()=>window.open("https://www.skyharbor.io/collection/ergosaurs")}>View on SkyHarbor</Button>
                <Button colorScheme="blue" size="lg" onClick={onClose}>Done!</Button>
              </ModalFooter>
            </ModalContent>
        </Modal>
      </Box>

    )
}
