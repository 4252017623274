import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Container,
    Button,
    HStack,
    Link,
    Stack,
    useDisclosure,
    Text,
    list,
    ListItem,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, QuestionOutlineIcon,HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useToast } from "@chakra-ui/react"
import WalletButton from './WalletButton'

export default function Nav({changeTheme, darkMode}) {
    let type = 'assembler';
    let walletSt = 'Configure';
    let userAddr = ''

    const toast = useToast()
    const navigate = useNavigate();
    // const { isOpen, onOpen, onClose } = useDisclosure();


    //For Modal
    const finalRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [processing, setProcessing] = useState(false);
    const [activeTab, setActiveTab] = useState(type);
    const [walletState, setWalletState] = useState(walletSt);
    const [userAddress, setUserAddress] = useState(userAddr);
    const [apiKey, setApiKey] = useState('');

    function gotoHome() {
        navigate("/")
    }
    function gotoAbout() {
        navigate("/info");
    }
    function gotoPage(page) {
        onClose();
        if(page === "/marketplace") {
            window.open("https://skyharbor.io/collection/ergosaurs", "_blank")
        }
        else {
            navigate(page);
        }
    }

    
    // function gotoAuctions() {
    //     navigate("/auctions");
    // }


    // const Links = ['Dashboard', 'Projects', 'Team'];
    const Links = [
        {
            title: "Home",
            page: "/"
        },
        // {
        //     title: "Auctions",
        //     page: "/auctions"
        // },
        {
            title: "Explore",
            page: "/explore"
        },

        {
            title: "Marketplace",
            page: "/marketplace"
        },
        
        {
            title: "Info",
            page: "/info"
        },
        
    ]


    const NavLink = ({children, page}) => (
        <Link
          px={2}
          py={1}
          fontSize={"lg"}
          fontWeight={"semibold"}
          rounded={'md'}
          onClick={()=>gotoPage(page)}
          _hover={{
            textDecoration: 'none',
            bg: !darkMode ? 'gray.200' : 'gray.700',
          }}
          >
          {children}
        </Link>
      );


    return (
        <>
            <Box style={{boxShadow: `0 3px 10px rgb(${!darkMode? '0 0 0':'255 255 255'} / 0.2)`}} px={4}>
                <Flex h={20} alignItems={'center'} justifyContent={'space-between'} >
                {/* <Container
                    as={Stack}
                    h={16}
                    maxW={'container.xl'}
                    //   py={4}
                    direction={{ base: 'row' }}
                    //   spacing={4}
                    padding="0"
                    justify={{ base:  'space-between' }}
                    align={{ base: 'center', md: 'center' }}> */}
                   


                    <Button 
                        onClick={isOpen ? onClose : onOpen}
                        bgColor={!darkMode ? "black" : "white"}
                        color={!darkMode ? "white" : "black"}
                        rounded='full'
                        display={{ md: 'none' }}
                    >
                        {isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    </Button>

                    <HStack spacing={8} alignItems={'center'}>
                        <Box>
                            {/* <Image
                                alt='Hero Image'
                                fit='contain'
                                align='center'
                                h='40px'
                                src={logoImage}
                                onClick={gotoHome}
                                // className="shadowFilter"
                            /> */}
                            <Text fontSize={{ base: '5xl', md: '5xl' }}  fontWeight={400} onClick={gotoHome} style={{cursor: "pointer", whiteSpace: "nowrap"}}>
                                🦖🦕
                            </Text>
                        </Box>

                        <HStack
                        as={'nav'}
                        spacing={4}
                        display={{ base: 'none', md: 'flex' }}>
                        {Links.map((link) => (
                            <NavLink key={link.title} page={link.page}>{link.title}</NavLink>
                        ))}
                        </HStack>
                        </HStack>

                    <Flex alignItems={'center'}>
                        <Stack direction={'row'} spacing={2} alignItems="center">
                            <Button 
                                onClick={changeTheme}
                                bgColor={!darkMode ? "black" : "white"}
                                color={!darkMode ? "white" : "black"}
                                rounded='full'
                            >
                                {darkMode === false ? <MoonIcon /> : <SunIcon />}
                            </Button>

                            <WalletButton />
                            </Stack>
                        </Flex>
                    {/* </Container> */}
                    </Flex>

                    {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                        {Links.map((link) => (
                            <NavLink key={link.title} page={link.page}>{link.title}</NavLink>
                        ))}
                        </Stack>
                    </Box>
                    ) : null}

            </Box>
        </>
    );
}