import React from 'react'
import { Router, Routes, Route, BrowserRouter, HashRouter } from 'react-router-dom'
import { Box, ChakraProvider, Container, extendTheme  } from "@chakra-ui/react"
import {Helmet} from "react-helmet";
import history from './history'
import Footer from './components/Footer/Footer'
import { Info } from './pages/Info/Info'
import { LandingPage } from './pages/LandingPage/LandingPage'
import { ExplorePage } from './pages/ExplorePage/ExplorePage'
import { AuctionsPage } from './pages/AuctionsPage/AuctionsPage'
import { MarketplacePage } from './pages/MarketplacePage/MarketplacePage'
// import { Home } from './pages/Home/Home'
import Navbar from './components/Navbar/Navbar'
import dinoImage from './assets/images/transparentdino1.png'
import bgDrawings from './assets/images/Outlines.png'
import ErgxodiaPage from './pages/ErgxodiaPage/ErgxodiaPage';
import DinoEggsPage from './pages/DinoEggs/DinoEggsPage';


const App = () => {

  const [darkMode, setDarkMode] = React.useState(false);

  const theme = extendTheme({
    colors: {
      brand: {
        100: "#4299e1"
      },
    },
    styles: {
      global: {
        // styles for the `body`
        body: {
          bg: darkMode ? "#121212" : "white",
          color: darkMode ? "white" : "black",
        },
      }
    }
  })
  function changeTheme () {
    localStorage.setItem("darkMode", !darkMode);
    setDarkMode(!darkMode)
  }

  React.useEffect(()=>{
    let isDark;
    if (localStorage) {
      isDark = localStorage.getItem("darkMode")
      // console.log("isDark", isDark)
    }
    if (isDark === "true") {
      setDarkMode(true);
    } 
    else if (isDark === "false") {
      setDarkMode(false);
    }
    else {
      setDarkMode(false);
    }
  }, []);
  const mainTitle = "Ergosaurs | ENFT Collection";
  const description = "The first collectible dinosaurs on the Ergo blockchain.";
  

  return (
    <ChakraProvider theme={theme}>
      {/* <Router navigator={history}> */}
      <HashRouter>
        <Navbar changeTheme={changeTheme} darkMode={darkMode}/>

        <Box
          bgImage={bgDrawings}
          bgSize="250px"
          // bgPosition="center"
          bgRepeat="repeat"
          
        >
          <Container 
            maxW="container.xl" minH={"100vh"}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/explore" element={<ExplorePage />} />
              <Route path="/auctions" element={<AuctionsPage />} />
              {/* <Route path="/marketplace" element={<MarketplacePage />} /> */}
              {/* <Route path="/home" component={Home} exact /> */}
              <Route path="/ergxodia" element={<ErgxodiaPage />} />
              <Route path="/dinoeggs" element={<DinoEggsPage />} />

              <Route path="/info" animate={true} element={<Info />} />
              <Route path="*" animate={true} element={<Box textAlign="center" mt="6" fontSize="3xl" height="50vh">404 Page Not Found</Box>} />
            </Routes>
          </Container >
          <Footer darkMode={darkMode}/>
        </Box>
        </HashRouter>

      {/* </Router> */}

      <Helmet>
        <title>{mainTitle}</title>
        <meta name="description" content={description}/>
        <meta property="og:title" content={mainTitle}/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" itemProp="image" content={dinoImage}/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={"https://ergosaurs.io"}/>
        <meta property="twitter:title" content={mainTitle}/>
        <meta property="twitter:description" content={description}/>
        <meta property="twitter:image" itemProp="image"  content={dinoImage}/>
        <meta property="twitter:card" content={dinoImage}/>
      </Helmet>
    </ChakraProvider>
  )
}

export default App
