import React from 'react';
import { useNavigate } from "react-router-dom";
import { Box, SimpleGrid, Button, Progress } from '@chakra-ui/react';
import { collection, getDocs,doc,  query, orderBy, where } from "firebase/firestore"; 
import { db } from '../../utils/firebase';
// import PropTypes from 'prop-types';
import Card from '../Card/Card';
import './CardList.css';
import Papa from 'papaparse'
import { parseMetadataFromCSV } from '../../auction/helpers';


// Landing Page example of our Ergosaurs

export const CardList = () => {
    const navigate = useNavigate();
    
    // const orderedList = list.sort((a, b) => (a.title > b.title) ? -1 : 1)
    const [dinoList, setDinoList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const gotoExplorePage = () => {
        navigate("/explore")
      }

    const getErgosaurs = () => {
        let ergosaursList = [];
        setLoading(true);

        const q = query(collection(db, "ergosaurs"), where("new", "==", true), orderBy("id", "desc"));

        getDocs(q)
        .then((querySnapshot)=> {
            querySnapshot.forEach((doc) => {
                // console.log(`${doc.id} => ${doc.data()}`);
                ergosaursList.push(doc.data());
            });
    
            setDinoList(ergosaursList);
            setLoading(false);
        });

        // list.forEach((doc) => {
        //     const docRef = addDoc(collection(db, "ergosaurs"), doc);
        // });
        // const docRef = addDoc(collection(db, "ergosaurs"), list[0]);   
    }


    async function getErgosaursFromCSV() {
        setLoading(true);

        const res = await parseMetadataFromCSV();
        const rev = res.reverse()
        const final = rev.slice(0,10)
        console.log("Res", final)

        setDinoList(final)
        setLoading(false)
    }

    React.useEffect(() => {
        // getErgosaurs();
        getErgosaursFromCSV();
    }, [])

    if(loading) {
        return (
            <Box textAlign="center">
                <Progress size="xs" isIndeterminate />
                <Box
                    color="gray.500"
                    letterSpacing="wide"
                    fontSize="xs"
                    mt="2"
                >
                    Loading Ergosaurs...
                </Box>
            </Box>
        )
    }

    return (
    <Box style={{marginBottom: "2rem"}}>
        <SimpleGrid columns={{ base: 2, sm:2, md: 3, lg: 4 }} spacing={4}>
            {
                dinoList.map(item => {
                    return (
                        <Card
                        key={item.name}
                        auction={false}
                        
                        title={item.name}
                        imageUrl={item.image}
                        description={item.description}
                        sold={item.sold_for_erg}
                        link={item.link}
                        tokenId={item.TokenID}
                        soldFor={item.sold_for_erg === "0" ? 0 : item.sold_for_erg}
                        // new={item.new}
                        royalty={parseInt(item.Royalty)}
                    />
                    )
                })
            }
            
        </SimpleGrid>
        <Box textAlign="center">
            <Button isFullWidth size="lg" mt="8" colorScheme="blue" onClick={gotoExplorePage}>Explore All</Button>
        </Box>
    </Box>
  );
}

export default CardList;
