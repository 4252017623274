import React, {useState, useRef} from 'react'
import { Box, SimpleGrid, Wrap, WrapItem, Progress, Text, Button, Heading } from '@chakra-ui/react';
// import { collection, addDoc } from "firebase/firestore"; 
// import { db } from '../../utils/firebase';

import Card from '../../components/Card/Card';
import NewErgosaursCard from '../../components/Card/NewErgosaursCard';
import {
    getAllActiveAuctions,
    currentBlock
} from '../../auction/explorer';
import {
    getWalletAddress,
    isWalletSaved,
    // showMsg,
    friendlyERG,
} from '../../auction/helpers';
import {
    decodeBoxes,
    longToCurrency
} from '../../auction/serializer';
import {assembleFinishedAuctions} from '../../auction/assembler';
import { useToast } from "@chakra-ui/react"
import SendModal from "../../components/SendModal/SendModal";
import NewAuctions from './NewAuctions';
import ResaleAuctions from './ResaleAuctions';
// import Refund from '../refund'
import "./AuctionsPage.css"
import { ergosaursAuctions } from '../../auction/consts'

// const artistAddress = '9gij9aVPmkoBek6MrZThxf3cKcpwgsrrtmBH8f5nM1xcdp3bySu'
// const artistAddress = '9hCqMZy97mi5qooyKzEWSJB4dcdCBoY4FRykNrcNy3wqcgZ4ayH' // ERGnomes
// const artistAddress = '9hX1nJkJu2hcsL2mQmwEtAsJUd94cyryzBFjXYSSzMdn6N87uDR' // ERGnomes 2
const artistAddress = '9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi' // Ergosaurs
const eggAddress = '9ezLzcm8hNnpPtneqmhs429JAi6RcxvYjiNTUiGQbVE9kGj5AWQ' // DinoEggs
const artistAddresses = `${artistAddress},${eggAddress}`
// const artistAddress = process.env.REACT_APP_ARTIST_ADDRESS;

// Update all explorer, assembler, and API calls to Auction House v2
// https://github.com/anon-real/ErgoAuctionHouse/tree/new/src/auction 

const updatePeriod = 30;

// const ergosaursL = [
//     {
//         color: "gold",
//         title: "Ergosaurs #7: Kangergo",
//         description: "A fighter at birth and killer by adult. Kangergo has spent years mastering the hidden tail technique, allowing him to attack with his hands and legs simultaneously. Herbivore. Part cyborg. | @Ergosaurs",
//         id: 7,
//         imageUrl: "https://cloudflare-ipfs.com/ipfs/bafybeiczi6tgoi35tg4bpe42zvvk4owaiyip3xh7o4lgrsf5wryuu5oytq",
//         link: "https://ergonfts.org/#/?token=f9061f37819e2acfb31e7981b74d5bebe03699a5996dc818bc90bb73f7030cd2",
//         new: true,
//         royalty: 50,
//         sold: true,
//         soldFor: 90,
//         tokenId: "f9061f37819e2acfb31e7981b74d5bebe03699a5996dc818bc90bb73f7030cd2"
//     }
// ]


function AuctionsWrapper() {
    const toast = useToast()
    // let bidSound = new Audio("../../assets/sounds/chaching.wav")

    // React Functional States
    const [firstTime, setFirstTime] = useState(true);
    const [loading, setLoading] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(0);
    const [sortKey, setSortKey] = useState("");
    const [error, setError] = useState(false);

    // for SendModal
    const [assemblerModal, setAssemblerModal] = useState(false);
    const [bidAddress, setBidAddress] = useState(false);
    const [bidAmount, setBidAmount] = useState(false);
    const [bidCurrency, setBidCurrency] = useState('ERG');
    const [isAuction, setIsAuction] = useState(false);

    // For Refund modal
    const [refund, setRefund] = useState(false);
    
    

    // Unfiltered Auctions list
    // const [auctionsList, setAuctionsList] = useState([]);

    // Filtered Auction List
    const [newFilteredAuctions, setNewFilteredAuctions] = useState([]);
    const [resaleFilteredAuctions, setResaleFilteredAuctions] = useState([]);
    // const [oldAuctionsList, setOldAuctionsList] = useState([]);

    // for time counter
    const countRef = useRef(lastUpdated);
    countRef.current = lastUpdated;

    // for prev state of auctions list
    const prevAuctions = useRef(newFilteredAuctions);
    prevAuctions.current = newFilteredAuctions;

    const gotoAuction = () => {
        window.open(ergosaursAuctions);
      }

    // SendModal activate
    function toggleAssemblerModal(address = '', bid = 0, isAuction = false, currency) {
        setAssemblerModal(!assemblerModal);
        setBidAddress(address);
        setBidAmount(bid);
        setBidCurrency(currency)
        setIsAuction(isAuction);
    }

    // New function to get boxes
    function sortAuctions(auctions, forceKey=null) {
        let key = sortKey.toString()
        if (forceKey) key = forceKey
        if (key === '0')
            auctions.sort((a, b) => a.remTimeTimestamp - b.remTimeTimestamp)
        else if (key === '1')
            auctions.sort((a, b) => b.remTimeTimestamp - a.remTimeTimestamp)
        else if (key === '2')
            auctions.sort((a, b) => {
                if (a.curBid < a.minBid) return 1
                if (b.curBid < b.minBid) return -1
                else return b.curBid - a.curBid
            })
        else if (key === '3')
            auctions.sort((a, b) => {
                if (a.curBid < a.minBid) return 1
                if (b.curBid < b.minBid) return -1
                else return a.curBid - b.curBid
            })
        else if (key === '4')
            auctions.sort((a, b) => b.creationHeight - a.creationHeight)
        else if (key === '5' && isWalletSaved())
            auctions.sort((a, b) => (b.seller === getWalletAddress()) - (a.seller === getWalletAddress()))
        else if (key === '6' && isWalletSaved())
            auctions.sort((a, b) => (b.bidder === getWalletAddress()) - (a.bidder === getWalletAddress()))
        else if (key === '7')
            auctions.sort((a, b) => (b.isFav - a.isFav))
        else if (key === '8')
            auctions.sort((a, b) => (b.currency === 'ERG') - (a.currency === 'ERG'))
        else if (key === '9')
            auctions.sort((a, b) => (b.currency !== 'ERG') - (a.currency !== 'ERG'))
        return auctions
    }

    async function updateAuctions() {
        setLoading(true);

        const block = await currentBlock().catch((error)=>{setError(true)})
        let boxes
        // if (this.state.specific) {
        //     boxes = [await followAuction(this.state.boxId)]
        // } else {
            boxes = await getAllActiveAuctions();
        // }

        const auctions = await decodeBoxes(boxes, block);
        console.log("auctions: ---", auctions)

        return auctions
    }
    function filterAuctions(auctions, saleType) {
        const artist = artistAddress
        let type = 'all'
        if (artist !== undefined) {
            let tempAuctions
            if(saleType === "resale") {
                tempAuctions = auctions.filter(auc => artistAddresses.split(',').includes(auc.artist) && auc.seller !== artistAddress)
            }
            else {
                tempAuctions = auctions.filter(auc => artist && auc.artist === artistAddress && auc.seller === artistAddress)
            }
            // tempAuctions = auctions.filter(auc => auc.seller === artistAddress)
            return tempAuctions
        }
        if (type) auctions = auctions.filter(auc => auc.type === type)
        return auctions
    }

    function calcValues(auctions) {
        let values = {ERG: 0}
        auctions.forEach(bx => {
            if (bx.curBid >= bx.minBid) {
                if (!Object.keys(values).includes(bx.currency))
                    values[bx.currency] = 0
                values[bx.currency] += bx.curBid
            }
        })
        return values
    }

    // If resale, we filter out every ergosaur being sold by the artist
    function getFilteredAuctions(aucs, saleType) {
        if(aucs){
            const auctions =  aucs;
            const filtered = filterAuctions(auctions, saleType);
            return sortAuctions(filtered);
        }else return []
    }

    // these functions check for a difference between the old auctions list and the new one.
    // If there is a difference in value for an auction, a toast pops up and possibly more.
    function checkForChange(oldAuc, newAuc) {

        // Code from here:
        // https://stackoverflow.com/questions/21987909/how-to-get-the-difference-between-two-arrays-of-objects-in-javascript/21988185
        
        // In future when multiples of one Ergosaur are released,
        // they share the same NFTID so need to update this code.
        let changedAuctions = []
        oldAuc.map((x)=> {
            const filteredValues = newAuc.filter(y => y.NFTID === x.NFTID && y.value!= x.value)
            filteredValues.map(i => {
                changedAuctions.push(i);
            })
        })

        if(changedAuctions.length !== 0) {
            // Play sound when new bids come in - need to add mute button first
            // bidSound.play();
            // Popup toast for each new auction
            changedAuctions.map((changed)=> {
                toast({
                    title: `New bid for ${changed.tokenName}!`,
                    description: `Bid is now ad ${friendlyERG(changed.value)} ERG!`,
                    status: "success",
                    position: "top-right",
                    duration: 10000,
                    isClosable: true,
                })
            })
        }
    }



    React.useEffect(()=>{
        // Use this code whenever adding data to firestore
        // ergosaursL.map((item)=> {
        //     addDoc(collection(db, "ergosaurs"), item);
        // })
        

        updateAuctions().then(auctions => {
            // setAuctionsList(auctions);
            setNewFilteredAuctions(getFilteredAuctions(auctions));
            setResaleFilteredAuctions(getFilteredAuctions(auctions, 'resale'));

            setLoading(false);
            setLastUpdated(0);
            setFirstTime(false);

            // assembleFinishedAuctions(auctions).then(r => {
            // })
        });

        const refreshTimer = setInterval(() => {
            let newLastUpdate = countRef.current + 1
            if ((newLastUpdate > updatePeriod) && !loading) {
                console.log("now updating...")
                updateAuctions().then(auctions => {
                    setLoading(false);
                    setLastUpdated(0);

                    // Set all auctions
                    // setAuctionsList(auctions);

                    // Set filtered auctions before update and notify
                    const tempNewFiltered = getFilteredAuctions(auctions);
                    checkForChange(prevAuctions.current, tempNewFiltered);

                    const tempResaleFiltered = getFilteredAuctions(auctions, 'resale');

                    // Set filtered Auctions
                    setNewFilteredAuctions(tempNewFiltered);
                    setResaleFilteredAuctions(tempResaleFiltered);
                })
            } 
            else {
                setLastUpdated(newLastUpdate);
            }
        }, 1000);

        return () => {
            if (refreshTimer !== undefined) {
                clearInterval(refreshTimer);
            }
        }
    }, []);

    // If loading
    if(loading && firstTime) {
        return (
            <Box textAlign="center" mt="4">
                <Progress size="xs" isIndeterminate />
                <Box
                    color="gray.500"
                    letterSpacing="wide"
                    fontSize="xs"
                    mt="4"
                >
                    Grabbing data from the Ergo blockchain, this might take a minute... <br />
                    If it takes more than 5 minutes, try refreshing or going to the <Box as="span" color="orange.400" style={{cursor: "pointer"}} onClick={gotoAuction}>Ergo Auction House</Box>
                </Box>
            </Box>
        )
    }
    // If no auctions
    if(error) {
        return (
            <Box textAlign="center">
                <Box
                    color="gray.500"
                    letterSpacing="wide"
                    fontSize="md"
                    mt="6"
                >
                    An error occurred, please refresh or check back later. <br />
                    You can also view the Ergosaurs on the Ergo Auction House.
                    <Button size="lg" onClick={gotoAuction}>View and bid at the Ergo Auction House</Button>
                </Box>
            </Box>
        )
    }

    // const finalList = getToShow();
    // const finalList = filteredAuctions;
    const totalList = newFilteredAuctions.concat(resaleFilteredAuctions)
    let values = calcValues(totalList)


    return (
    <Box>
        {/* Component directly implemented works, but not with seperate functional component */}
        {/* <NewAuctions /> */}
        <Box textAlign={"center"}>

        <Text fontSize="md" mb="1">{totalList.length} auctions worth a total of {longToCurrency(values.ERG, -1, 'ERG')} ERG. You can also view them in the <Box as="span" color="orange.400" style={{cursor: "pointer"}} onClick={gotoAuction}>Ergo Auction House</Box>.</Text>
        <Text fontSize="md" mb="4">Updated {lastUpdated} seconds ago</Text>
        </Box>
        {/* <hr /> */}

        <NewAuctions auctions={newFilteredAuctions} toggleAssemblerModal={toggleAssemblerModal}/>

        
        <ResaleAuctions auctions={resaleFilteredAuctions} toggleAssemblerModal={toggleAssemblerModal}/>


        <SendModal
        isOpen={assemblerModal}
        close={toggleAssemblerModal}
        bidAmount={bidAmount}
        isAuction={isAuction}
        bidAddress={bidAddress}
        currency={bidCurrency}
    />
    </Box>

    )
    
}

export default AuctionsWrapper;
