import { AspectRatio, Box, Heading, Image, SimpleGrid, Text, HStack } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";

import React from 'react'
import head from '../../assets/images/head.png'
import dinEgg1 from '../../assets/images/0023.png'
import { CheckCircleIcon } from '@chakra-ui/icons';

export default function Events() {
    const navigate = useNavigate()

    const EventItem = ({img, title, description, page, isFinished}) => {

        return (
            <Box m="8" borderRadius="lg" _hover={{opacity: 0.5}} transition="all 0.2s" cursor="pointer" onClick={()=>navigate(page)}>
                <Box w="100%" mb="4">
                    <AspectRatio  m="auto" ratio={1} maxH={300} maxW={300}>
                        <Image borderRadius={"lg"} src={img}/>
                    </AspectRatio>
                </Box>

                <Box textAlign={"center"}>
                    <Text mb="2" fontWeight={"semibold"} fontSize="xl">{title}</Text>

                    {
                        isFinished &&
                        <HStack justifyContent={"center"}  mb="2">
                            <CheckCircleIcon color="green.400" fontSize={"xl"}/> 
                            <Heading textAlign={"center"} fontSize="lg">
                                Event Finished
                            </Heading>
                            <CheckCircleIcon color="green.400" fontSize={"xl"}/>
                        </HStack>
                    }

                    <Text >{description}</Text>
                </Box>
            </Box>
        )

    }

  return (
      <Box>
          <Heading mt="8" mb="4" textAlign={"center"}>Events</Heading>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 2 }} spacing={4}>
              <EventItem isFinished page={"dinoeggs"} img={dinEgg1} title="DinoEggs" description="598 Dinosaur Eggs randomly generated so each and every one is unique."/>
              <EventItem isFinished page={"ergxodia"} img={head} title="ERGXODIA" description={"The legendary ERGXODIA pieces have been scattered across the Ergozoic era, landing in various nests..."}/>
          </SimpleGrid>
      </Box>
  )
}
