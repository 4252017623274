import React, { Fragment } from 'react';
import { Box, SimpleGrid, Text, Progress, Input } from '@chakra-ui/react';
import { collection, getDocs,doc,  query, orderBy, where } from "firebase/firestore"; 
import { db } from '../../utils/firebase';
// import PropTypes from 'prop-types';
import Card from '../Card/Card';
import './CardList.css';
import Papa from 'papaparse'
import { parseMetadataFromCSV } from '../../auction/helpers';

export const CardList = () => {
    // const orderedList = list.sort((a, b) => (a.title > b.title) ? -1 : 1)
    const [dinoList, setDinoList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const getErgosaurs = () => {
        let ergosaursList = [];
        setLoading(true);

        const q = query(collection(db, "ergosaurs"), orderBy("id", "desc"));

        getDocs(q)
        .then((querySnapshot)=> {
            querySnapshot.forEach((doc) => {
                // console.log(`${doc.id} => ${doc.data()}`);
                ergosaursList.push(doc.data());
            });
    
            setDinoList(ergosaursList);
            setLoading(false);
        });

        // list.forEach((doc) => {
        //     const docRef = addDoc(collection(db, "ergosaurs"), doc);
        // });
        // const docRef = addDoc(collection(db, "ergosaurs"), list[0]);
    }

    const searchedList = () => {
        if(search.replace(/\s/g, '') === "") {
            return dinoList;
        }
        else {
            let searchList = dinoList.filter(item => item.title.toLowerCase().includes(search.toLowerCase()) || item.color.toLowerCase().includes(search.toLowerCase()))
            console.log(searchList)
            return searchList
        }
    }


    async function getErgosaursFromCSV() {
        setLoading(true)
        const res = await parseMetadataFromCSV();
        console.log("Res", res)
        setDinoList(res)
        setLoading(false)
    }

    React.useEffect(() => {
        // getErgosaurs();
        getErgosaursFromCSV();
    }, [])

    if(loading) {
        return (
            <Box textAlign="center" mt="4">
                <Progress size="xs" isIndeterminate />
                <Box
                    color="gray.500"
                    letterSpacing="wide"
                    fontSize="xs"
                    mt="2"
                >
                    Loading Ergosaurs...
                </Box>
            </Box>
        )
    }

    


    const ErgosaurSearch = () => {
        let finalList = searchedList();

        if(finalList.length === 0) {
            return (
                <Fragment>
                    <Box textAlign="center">
                        <Box
                            color="gray.500"
                            letterSpacing="wide"
                            mt="4"
                        >
                            No Ergosaurs can be found.
                        </Box>
                    </Box>
                </Fragment>
            )
        }
    
        return (
        <Fragment>
    
            <SimpleGrid columns={{ base: 1, sm:2, md: 3, lg: 4 }} spacing={4}>
                {
                    finalList.map(item => {
                        return (
                        <Card
                            key={item.name}
                            auction={false}
                            
                            title={item.name}
                            imageUrl={item.image}
                            description={item.description}
                            sold={item.sold_for_erg}
                            link={item.link}
                            tokenId={item.TokenID}
                            soldFor={item.sold_for_erg === "0" ? 0 : item.sold_for_erg}
                            // new={item.new}
                            royalty={parseInt(item.Royalty)}
                        />
                        )
                    })
                }
                
            </SimpleGrid>
        </Fragment>
      );
    }

    

    return (
        <Box style={{marginBottom: "2rem"}}>
            <Input mb="3" size="lg" type="text" name="search" id="search" colorScheme={"blackAlpha"} variant="filled" placeholder="Search name, color, #..."
                value={search}
                onChange={(event) =>
                    setSearch(event.target.value)
                }
            />

            <Text mb="4" fontSize={"sm"} color="gray.500">Sorted from new to old</Text>
            <ErgosaurSearch />

        </Box>
    )
}

export default CardList;
