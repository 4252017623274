import React from 'react'
import { useNavigate } from "react-router-dom";
import { scroller } from 'react-scroll'
import {
    Box,
    Stack,
    Flex,
    // Box,
    Heading,
    Text,
    Button,
    Image,
    Tooltip,
    // createIcon,
  } from '@chakra-ui/react';
  import dinoImage from '../../assets/images/transparentdino1.png'
  import { FaTwitter, FaDiscord  } from 'react-icons/fa';

  // const logoImage = require('../../assets/images/Ergosaurslogo.png')
  import logoImage from '../../assets/images/Ergosaurslogo.png'

export const HomeTop = () => {
  const navigate = useNavigate();

  const gotoReleased = () => {
    scroller.scrollTo('released', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }
  const gotoTwitter = () => {
    window.open("https://twitter.com/ergosaurs");
  }
  const inviteDiscord = () => {
    window.open("https://discord.gg/KrRfJwGnG4");
  }
  const checkArtist = () => {
    navigate("/info")
    // window.open("https://twitter.com/ergosaurs/status/1440745330039930883");
  }
  const gotoAuctions = () => {
    // scroller.scrollTo('auctions', {
    //   duration: 800,
    //   delay: 0,
    //   smooth: 'easeInOutQuart'
    // });
      navigate("/auctions")

  }
  const gotoExplorePage = () => {
    navigate("/explore")
  }

  const gotoMarketplace = () => {
    navigate("/marketplace")
  }


    return (
    // <Container maxW='7xl'>
      // <Stack
      //   align='center'
      //   spacing={{ base: 8, md: 10 }}
      //   py={{ base: 6, md: 18 }}
      //   direction={{ base: 'column', md: 'row' }}>
      <Flex direction={{ base: 'column', md: 'row' }} mt="6" >
        <Stack flex={1} spacing={{ base: 4, md: 4 }}>
          <Heading
            mt="4"
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}>
            {/* <Image
              alt='Hero Image'
              fit='contain'
              align='center'
              w='100%'
              h='100%'
              // borderRadius={18}
              src={logoImage}
              className="shadowFilter"
            /> */}
            <Text color='black.500'
            as={'span'}
            position={'relative'}
            _after={{
              content: "''",
              width: 'full',
              height: '15%',
              position: 'absolute',
              bottom: 1,
              left: 0,
              bg: 'blue.300',
              zIndex: -1,
            }}
            >
              Ergosaurs NFT Collection
            </Text>
          </Heading>
          <Stack flex={1} spacing={{ base: 1}}>
            <Text color='black.500' fontSize='x-large' fontWeight={500}>
              The first collectible dinosaurs on the Ergo blockchain.
            </Text>
            <Text>
              Each Ergosaur is one-of-a-kind and hand-drawn, with a strive to create rarity and pride in ownership.
            </Text>
            <Box mb="4">
              Only a limited amount will be made and when the cap is reached, we will continue to create a ripe environment for trading and a sense of community. 
              View our released Ergosaurs now!
              <Text mt="3" size="sm" ><span style={{fontWeight: "bolder"}}>Artist Address:</span> 9hS38ddo6GmP31xvCDkBpWCcD1saNgszsmK4FNgEdieGb27PUmi - <span onClick={checkArtist} style={{color: "gray", cursor: "pointer"}}>click to learn more.</span></Text>
            </Box>
            {/* <Stack
              pt="4"
              spacing={{ base: 2, sm: 3 }}
              direction={{ base: 'column', sm: 'row' }}
              > */}
              {/* <Tooltip label="No auctions currently happening" hasArrow placement="bottom"> */}
                <Stack  pt="4" direction={{base: "column", md: "row"}}>
                  <Button
                  // mt="4"
                  // isFullWidth
                    onClick={gotoMarketplace}
                    size='lg'
                    // px={12}
                    colorScheme="blue"
                    bg='blue.400'
                    // disabled={true}
                    _hover={{ bg: 'blue.600' }}
                    >
                    Go To Marketplace
                  </Button>
                  <Button
                  // mt="4"
                  // isFullWidth
                    onClick={gotoAuctions}
                    size='lg'
                    // px={12}
                    colorScheme="blue"
                    bg='blue.400'
                    // disabled={true}
                    _hover={{ bg: 'blue.600' }}
                    >
                    Go To Auctions
                  </Button>
                </Stack>
              {/* </Tooltip> */}
              {/* <Button
                onClick={gotoExplorePage}
                // rounded='full'
                size='lg'
                // fontWeight='normal'
                px={12}
                colorScheme='red'
                bg='red.500'
                _hover={{ bg: 'red.600' }}>
                Explore
              </Button>
              
              <Stack
                spacing={{ base: 2, sm: 3 }}
                direction={{ base: 'row', sm: 'row' }} >

                  <Button onClick={gotoTwitter} size='lg' colorScheme="twitter">
                    <FaTwitter />
                  </Button>
                  <Button onClick={inviteDiscord} size='lg'  colorScheme="purple">
                    <FaDiscord />
                  </Button>
              </Stack> */}

            {/* </Stack> */}
          </Stack>
        </Stack>
        <Flex
          // flex={1}
          justify='center'
          align='center'
          position='relative'
          className="shadowFilter"
          display={{base: 'none', md: "inherit"}}
          ml="16"
          w='400px'>
            <Image
              alt='Hero Image'
              fit='contain'
              align='center'
              // w='70%'
              h='100%'
              // borderRadius={18}
              src={dinoImage}
            />
        </Flex>
      </Flex>
      // </Container>
    );
  }
  