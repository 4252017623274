import React, { Fragment } from 'react';
import {
    Heading,
    Text,
    Stack,
    HStack,
    Flex,
    Button, ListIcon, Box, Image, ListItem, List,
    Modal,
    ModalContent,
    ModalCloseButton,
    ModalOverlay,
    ModalBody,
    ModalHeader,
    ModalFooter,
    useDisclosure,
    Divider,
    Progress
  
  } from "@chakra-ui/react";
  import rarityChart from '../../assets/images/raritychart.png'
  import { useNavigate } from "react-router-dom";

export default function RarityButton() {
  const navigate = useNavigate()

    const finalRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const gotoInfo = () => {
      navigate("info")

    }

  return (
      <Fragment>
        <Button colorScheme={"blue"} variant={"outline"} onClick={onOpen}>Rarity Chart</Button>

        {/* Rarity Chart Modal */}
        <Modal isCentered finalFocusRef={finalRef} size="4xl" isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
          <ModalOverlay />
            <ModalContent color="black" overflow="hidden" margin="4" bgColor="transparent" shadow={"none"} >
              {/* <ModalCloseButton color="white" bgColor="blue.400" _hover={{bgColor: "red"}}/> */}

              {/* <ModalHeader>Send ERG to recieve your DinoEgg</ModalHeader> */}
              <Box>
                <Image 
                    // style={{objectPosition: "0px -70px"}}
                    // mt="6"
                    // mb="2"
                    borderBottomRadius={6}
                    alt='Hero Image'
                    fit='contain'
                    align='center'
                    // maxW={'100%'}
                    src={rarityChart}                
                />
                </Box>
                <Divider />
              {/* <ModalBody>
                <Box textAlign={"center"}>
                </Box>
              </ModalBody> */}

              <ModalFooter>
                <Button  mr="4" size="lg" onClick={onClose}>close</Button>
                <Button colorScheme="blue" size="lg" onClick={gotoInfo}>View on mobile</Button>
              </ModalFooter>
            </ModalContent>
        </Modal>
      </Fragment>
  );
}
