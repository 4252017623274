import React, { Fragment }  from 'react';
import {
  Box,
  Image,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react"
import Countdown from 'react-countdown';
import { useToast } from "@chakra-ui/react"
import { friendlyERG } from '../../../auction/helpers';
import {currencyToLong, isFloat, longToCurrency} from '../../../auction/serializer';


// Countdown timer custom component
// Random component
const Completionist = () => <span>Auction Ended!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span> <span style={{fontWeight: "500"}}>Auction Ends:</span> {days} day{days !== 1 && 's'} {hours} hour{hours !== 1 && 's'} and {minutes} minute{minutes !== 1 && 's'}</span>;
  }
};

// Start of Modal Component
const ModalInfo = ({
  imgUrl,
  description, 
  title, 
  value, 
  percentIncrease,
  artist,
  seller,
  bidder,
  endTime,
  remTime,
  box
}) => {

  const toast = useToast()

  const handleCopyAddress = (text) => {
    navigator.clipboard.writeText(text).then(()=> toast({
      title: "Copied Address",
      // description: "We've created your account for you.",
      position: "top-right",
      status: "info",
      duration: 5000,
      isClosable: true,
    }))
  }



  // the higher the bid, the larger the number
  const percentSize = (value / 1e9)/4 + 10;
  const shakeShreshold = 35;


  return (
    <Fragment>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 1}}>
        <Box padding={0} overflow="hidden">
          <Image objectFit="contain" src={imgUrl} alt={description}/>
        </Box>

        <Box mt="0" ml="4"mr="4">

          {/* NFT Title and Description */}
          <Box mt="1"  as="p" fontWeight="semibold" fontSize="xl" textAlign="center">
            {title}
          </Box>
          <Box mt="1" mb="2" as="p" color="gray.700" fontSize="lg" textAlign="center" >
            {/* <span style={{fontWeight: "500"}}>Description:</span>  */}
            {description}
          </Box>

          <hr />

          {/* Artist */}
          <Box as="div" mt="2">
            <Tooltip label="Click to copy artist address">
              <Box color="gray.600" fontSize="sm" as="div" d="flex" onClick={() => handleCopyAddress(artist)}>
                <span style={{fontWeight: "500"}}>Artist:</span> 
                <Box style={{marginLeft: 4}} noOfLines={1} >
                  {artist}
                </Box>
              </Box>
            </Tooltip>
          </Box>

          {/* Seller */}
          <Box mt="1"  as="div">
            <Tooltip label="Click to copy seller address">
              <Box mt="1" color="gray.600" fontSize="sm" as="div" d="flex" onClick={() => handleCopyAddress(seller)}>
                <span style={{fontWeight: "500"}}>Seller:</span>
                <Box style={{marginLeft: 4}} noOfLines={1} >
                  {seller}
                </Box>
              </Box>
            </Tooltip>
          </Box>

          {/* Bidder */}
          <Tooltip label="Click to copy bidder address">
            <Box mt="1" color="gray.600" fontSize="sm" as="div" d="flex" onClick={() => handleCopyAddress(bidder)} >
              <span style={{fontWeight: "500"}}>Bidder:</span>
              <Box style={{marginLeft: 4}} noOfLines={1} >
                {bidder}
              </Box>
            </Box>
          </Tooltip>

          {/* Countdown */}
          <Box  mt="1"  fontFamily="inherit" color="orange.400" fontSize="sm" d="flex">
            {/* Auction ends in {endTime} */}

            <Countdown 
              date={new Date(endTime)} 
              renderer={renderer}
            />
          </Box>

          {/* Price */}
          <Box fontWeight="semibold" mt="1" as="div" justifyContent="right" display="flex" alignItems="baseline">
            <Box  as="h4">
              {/* <span >Price:</span>{' '}{friendlyERG(value)} ERG */}
              Current Bid: {seller != bidder ? `${longToCurrency(box.curBid, -1, box.currency)} ${box.currency}` : "none"}
            </Box>
            <Box ml="1" color="green.300" 
              style={{
                fontSize:(percentSize) > 20 ? 20 : percentSize, 
                animation: percentSize > shakeShreshold  ? '2s shake infinite alternate' : 'none'}} 
              className="shakeAnimation"
            >
              {percentIncrease}%^
            </Box>
          </Box>
          
        </Box>
      </SimpleGrid>
    </Fragment>
  )
}


  export default ModalInfo;