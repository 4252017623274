import React from 'react';
import {
    Box,
    Badge,
    Image,
    Tooltip,
    Button,
    useDisclosure,
    Modal,
    ModalContent,
    ModalCloseButton,
    ModalOverlay,
    ModalBody,
    ModalHeader,
    ModalFooter,    
    List, 
    ListItem, 
    ListIcon, 
    OrderedList, 
    UnorderedList,
    
} from "@chakra-ui/react"
import { useToast } from "@chakra-ui/react"
// import {Button, Container, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {friendlyAddress, isAssembler, showMsg} from '../../auction/helpers';
import QRCode from "react-qr-code";
import {additionalData, supportedCurrencies, txFee} from "../../auction/consts";

export default function SendModal(props) {
    const toast = useToast()

    const handleCopyAddress = (text, message = "Copied to clipboard") => {
        navigator.clipboard.writeText(text).then(()=> toast({
          title: message,
          // description: "We've created your account for you.",
          position: "top-right",
          status: "info",
          duration: 5000,
          isClosable: true,
        }))
      }


    return (
        <Modal
            isOpen={props.isOpen}
            backdrop="static"
            toggle={props.close}
            size='md'
        >
          <ModalOverlay />
          <ModalContent color="black" overflow="hidden" margin="4">

            <ModalHeader color="gray.700" fontSize="xl" textAlign="center">
                Good luck on your new Ergosaur bid!
            </ModalHeader>
            <ModalBody>
                <Box>
                    <Box color="gray.700" fontSize="lg" mb="4" textAlign="center">
                        *Click on the amount and the address to copy them*
                    </Box>
                    <p>
                        Send{' '}
                        <Tooltip label="Click to copy ERG amount" hasArrow>
                            <span style={{fontWeight: "500"}} onClick={() => handleCopyAddress(props.bidAmount, "Copied ERG amount")}>
                                exactly{' '}
                                {props.bidAmount}{' '}{props.currency}
                            </span>
                        </Tooltip>
                        {props.isAuction && <span>
                            {props.currency !== 'ERG' && 
                            <Box onClick={() => handleCopyAddress(props.bidAmount, "Copied ERG amount")}>
                                {', '}{(parseInt(additionalData.dataInput.additionalRegisters.R8.renderedValue) + supportedCurrencies.ERG.minSupported) / 1e9} ERG
                            </Box>}
                            {' '}and the <b>token</b> you want to auction</span>}{' '}
                        to this address:{' '}
                        <Tooltip label="Click to copy address" hasArrow>
                            <Box style={{fontWeight: "500"}} onClick={() => handleCopyAddress(props.bidAddress, "Copied address")}>
                                {friendlyAddress(props.bidAddress)}
                            </Box>
                        </Tooltip>
                        <b
                            onClick={() => handleCopyAddress(props.bidAddress, "Copied address")}
                        ></b>
                        <UnorderedList mt="4">
                            <ListItem fontSize="sm">
                                <span style={{fontWeight: "500"}}>Please wait a couple minutes</span> after sending funds, the blockchain must process and confirm the transaction.
                            </ListItem>
                            <ListItem fontSize="sm">
                                The operation will be done as soon as the funds are sent and confirmed.
                            </ListItem>
                            <ListItem fontSize="sm">
                                This is all run by smart contracts. Your funds are safe - if outbid or failed, you will be refunded. Find out more about how{' '}
                                <a
                                style={{color: "cadetblue"}}
                                    target="_blank"
                                    href="https://www.ergoforum.org/t/some-details-about-ergo-auction-house/428/6"
                                >
                                    here.
                                </a>
                            </ListItem>
                            <ListItem fontSize="sm">
                                You can send the requested funds in multiple transactions.
                            </ListItem>
                            {props.isAuction && <ListItem fontSize="sm">
                                Starting auction fee (<b>{parseInt(additionalData.dataInput.additionalRegisters.R8.renderedValue) / 1e9} ERG</b>) is included in the funds above.
                            </ListItem>}
                            <ListItem fontSize="sm">
                                You have a limited time to send the funds.
                            </ListItem>
                            {props.isAuction && isAssembler() && <ListItem fontSize="sm">
                                <b>Follow: 1- Scan the QR code 2- Add the token that you want to auction 3- Send the transaction </b>
                            </ListItem>}
                        </UnorderedList>
                    </p>
                    <Box mt="4" style={{width: "100%", textAlign: "-webkit-center"}}>
                        {props.currency === 'ERG' && 
                            <QRCode
                                size={200}
                                value={"https://explorer.ergoplatform.com/payment-request?address=" + props.bidAddress +
                                "&amount=" + props.bidAmount}/>
                        }
                        {props.currency !== 'ERG' && props.isAuction && <QRCode
                            size={200}
                            value={"https://explorer.ergoplatform.com/payment-request?address=" + props.bidAddress +
                            "&amount=" + ((supportedCurrencies.ERG.minSupported + parseInt(additionalData.dataInput.additionalRegisters.R8.renderedValue)) / 1e9) +`&${supportedCurrencies[props.currency].id}=${props.bidAmount}`}/>}
                        {props.currency !== 'ERG' && !props.isAuction && props.currency && <QRCode
                            size={200}
                            value={"https://explorer.ergoplatform.com/payment-request?address=" + props.bidAddress +
                            "&amount=" + 4 * (txFee / 1e9) + `&${supportedCurrencies[props.currency].id}=${props.bidAmount}`}/>}
                    </Box>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="ml-3 mr-3 btn-transition"
                    color="secondary"
                    onClick={props.close}
                >
                    OK
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    );
}