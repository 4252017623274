import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {handleAll, pendings} from "./auction/assembler";
import {additionalData, auctionNFT} from "./auction/consts";
import {currentHeight, getBoxesForAsset,} from './auction/explorer';

// import {bidFollower} from "./auction/assembler";

const renderApp = (App) => {

  handleAll().then(res => {})
    setInterval(() => {
        handleAll().then(res => {})
  }, 60000);

  // function updateDataInput() {
  //     unspentBoxesFor(dataInputAddress)
  //         .then((res) => {
  //             return res.filter(
  //                 (box) =>
  //                     box.assets.length > 0 &&
  //                     box.assets[0].tokenId === auctionNFT
  //             );
  //         })
  //         .then((res) => (additionalData['dataInput'] = res[0]))
  //         .catch(() =>
  //           console.log('could not load data Input...')
  //         );
  // }

  // updateDataInput();
  // setInterval(() => {
  //     currentHeight().then(height => {
  //         handlePendingBids(height);
  //     })
  // }, 60000);
  // setInterval(() => {
  //     updateDataInput();
  // }, 120000);
  // setInterval(() => {
  //     bidFollower();
  // }, 15000);

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
};

renderApp(App);











// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
