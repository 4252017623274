import { Heading, Box, Image, HStack, Text, Container } from '@chakra-ui/react'
import React, {useEffect} from 'react'
import { FaParachuteBox } from 'react-icons/fa'
import { MdOutlineRotate90DegreesCcw } from 'react-icons/md'
import { AiFillFire } from 'react-icons/ai'
import { GiDinosaurBones, GiParachute } from 'react-icons/gi'
import { BiDonateBlood , BiCollection} from 'react-icons/bi'
import { BsCollection } from 'react-icons/bs'
import dinEgg1 from '../../assets/images/0023.png'
import head from '../../assets/images/head.png'
import leftarm from '../../assets/images/leftarm.png'
import rightarm from '../../assets/images/rightarm.png'
import leftleg from '../../assets/images/leftleg.png'
import rightleg from '../../assets/images/rightleg.png'
import { CheckCircleIcon } from '@chakra-ui/icons'


export default function ErgxodiaPage() {

  function gotoSkyharbor () {
    window.open("https://skyharbor.io")
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    
  return (
    <Box>
        <Heading fontSize={"8vw"} mb="4" textAlign={"center"} mt="16">E R G X O D I A</Heading>
        <HStack justifyContent={"center"} >
          <CheckCircleIcon color="green.400" fontSize={"3xl"}/> 
          <Heading textAlign={"center"}>
            Event Finished 
          </Heading>
          <CheckCircleIcon color="green.400" fontSize={"3xl"}/>
        </HStack>
        <Text mt="2" mb="8" textAlign={"center"} fontWeight="semibold">10 Hero Ergosaurs Minted</Text>

        {/* <Heading fontSize={20} mb="16" fontWeight={"500"} textAlign={"center"}>- DinoEggs Airdrop -</Heading> */}

        <Box zIndex={10} mt="60" ml="-1rem" position={"absolute"} px="4"
        left={0}
        right= {0}
        marginLeft= "auto" 
        marginRight="auto" 
        // width="600px"
        maxW={700}
        minW={300}
        >
          <Container zIndex={100} color="black" borderWidth={1} borderColor="gray.400" bgColor={"white"} borderRadius={12} boxShadow={"lg"} px={10} pb={10} pt={6} >
            <Heading mb="2">Airdrop Info!</Heading>
            <Text color="gray.800" mb="3">The legendary ERGXODIA pieces have been scattered across the Ergozoic era, landing in various nests...</Text>
            <HStack spacing={4}>
              <Box>
                <GiParachute size={30}/> 
              </Box>
              <Box>
                <Text fontWeight={"500"} fontSize="xl">Raffle Airdrop</Text>
                <Text fontSize={"md"}>All DinoEgg owners will be entered into a raffle to win 1 of 100 ERGXODIA pieces, 5 limbs, 20 of each. Snapshot is taken at midnight EST on May 26th. ERGXODIA pieces are then airdropped to the winners the following day.</Text>
              </Box>
            </HStack>

            <HStack mt="3" spacing={4}>
            <Box>

              <BiCollection size={30}/> 
              </Box>
              <Box>
                <Text fontWeight={"500"} fontSize="xl">Collect</Text>
                <Text>Collect all five pieces and make ERGXODIA whole. With only 20 of each piece, it should be a challenge. These pieces will be available to list on <Text as="span" cursor={"pointer"} color="blue.500" onClick={gotoSkyharbor}>SkyHarbor.io</Text>.</Text>
              </Box>
            </HStack>
            <HStack mt="3" spacing={4}>
              <Box>
              <BiDonateBlood size={30}/> 
              </Box>
              <Box>
                <Text fontWeight={"500"} fontSize="xl">Trade In</Text>
                <Text>If you collect all 5 limbs in time, you have the option to trade them in. You will then recieve a unique variation of the Ergosaurs Hero series and the ERGXODIA pieces will be burned! </Text>
              </Box>
            </HStack>
            <HStack mt="3" spacing={4}>
            <Box>
              <GiDinosaurBones size={30}/> 
              </Box>
              <Box>
                <Text fontWeight={"500"} fontSize="xl">Burn</Text>
                <Text>All remaining DinoEggs will be burned after the airdrop! 🔥🔥🔥</Text>
              </Box>
            </HStack>

          </Container>
        </Box>

    <Box opacity={0.7}>

        <Box w="100%" mb="16" zIndex={0} >
            <Box>
                <Image
                  // borderBottomRightRadius={{ base: '0', md: 20 }}
                  // borderTopLeftRadius={{ base: 20, md: '0' }}
                  // borderTopRightRadius={20}
                //   width={{ base: '50%' , lg: 300}} 
                  // borderTopRadius={6}
                  m="auto"
                  alt='Hero Image'
                  fit='cover'
                  align='center'
                  borderRadius={"100%"}
                  width={300}
                  height={300}
                //   maxW={'100%'}
                  src={head}
                />            
            </Box>
        </Box>
        <HStack w="100%" mb="20" justifyContent={"space-around"}>
            <Box>
                <Image
                  // borderBottomRightRadius={{ base: '0', md: 20 }}
                  // borderTopLeftRadius={{ base: 20, md: '0' }}
                  // borderTopRightRadius={20}
                //   width={{ base: '50%' , lg: 300}} 
                  // borderTopRadius={6}
                  m="auto"
                  alt='Hero Image'
                  fit='cover'
                  align='center'
                  borderRadius={"8"}
                  
                  width={300}
                  height={300}
                //   maxW={'100%'}
                  src={rightarm}
                />            
            </Box>
            <Box>
                <Image
                  // borderBottomRightRadius={{ base: '0', md: 20 }}
                  // borderTopLeftRadius={{ base: 20, md: '0' }}
                  // borderTopRightRadius={20}
                //   width={{ base: '50%' , lg: 300}} 
                  // borderTopRadius={6}
                  m="auto"
                  alt='Hero Image'
                  borderRadius={"8"}
                  fit='cover'
                  align='center'
                  width={300}
                  height={300}
                //   maxW={'100%'}
                  src={leftarm}
                />            
            </Box>
        </HStack>
        <HStack w="100%" justifyContent={"space-around"}>
          <Box zIndex={-10}>
                <Image
                  // borderBottomRightRadius={{ base: '0', md: 20 }}
                  // borderTopLeftRadius={{ base: 20, md: '0' }}
                  // borderTopRightRadius={20}
                //   width={{ base: '50%' , lg: 300}} 
                  // borderTopRadius={6}
                  m="auto"
                  borderRadius={"8"}
                  alt='Hero Image'
                  fit='cover'
                  align='center'
                  width={300}
                  height={300}
                //   maxW={'100%'}
                  src={rightleg}
                />            
            </Box>
            <Box zIndex={-10}>
                <Image
                  // borderBottomRightRadius={{ base: '0', md: 20 }}
                  // borderTopLeftRadius={{ base: 20, md: '0' }}
                  // borderTopRightRadius={20}
                //   width={{ base: '50%' , lg: 300}} 
                  // borderTopRadius={6}
                  m="auto"
                  borderRadius={"8"}
                  alt='Hero Image'
                  fit='cover'
                  align='center'
                  width={300}
                  height={300}
                //   maxW={'100%'}
                  src={leftleg}
                />            
            </Box>
        </HStack>
        </Box>


    </Box>
  )
}
